import { call, put } from 'redux-saga/effects';

import { Creators as UserActions } from '../ducks/users';

import API from '../../services/api';

export function* getUser(action) {
  try {
    const { payload } = action;
    const { data } = yield call(API.get, `/paciente/${payload.id}`);
    if (data.records) {
      const { records } = data;
      yield put(UserActions.getUserSuccess(records));
    } else {
      yield put(UserActions.getUserFailure('Usuário inexistente!'));
    }
  } catch (err) {
    yield put(
      UserActions.getUserFailure(
        'Erro interno no módulo de buscar os dados do usuário!'
      )
    );
  }
}

export function* saveUser(action) {
  try {
    const { payload } = action;

    let response = null;
    if (payload.rowbody.cod_paciente) {
      response = yield call(
        API.put,
        `/paciente/${payload.rowbody.cod_paciente}`,
        payload.rowbody
      );
    } else {
      response = yield call(API.post, '/paciente/', payload.rowbody);
    }

    const { data } = response;
    if (data.records.error) {
      yield put(UserActions.saveUserFailure(data.records.msg));
    } else {
      yield put(UserActions.saveUserSuccess(data.records.msg));
    }
  } catch (err) {
    yield put(
      UserActions.saveUserFailure(
        'Erro interno no módulo de salvar os dados do usuário!'
      )
    );
  }
}

export function* getAnamnese(action) {
  try {
    const { payload } = action;
    const { data } = yield call(API.get, `/paciente/anamnese/${payload.id}`);

    if (data.records.error) {
      yield put(UserActions.getAnamneseFailure(data.records.msg));
    } else {
      const { records } = data;
      const anamnese = {
        id: records.cod_grupo_anamnese,
        anamnese: records.anamnese,
      };
      yield put(UserActions.getAnamneseSuccess(anamnese));
    }
  } catch (err) {
    yield put(
      UserActions.getAnamneseFailure(
        'Erro interno no módulo de recolher os dados da anamnese do paciente!'
      )
    );
  }
}

export function* saveAnamnese(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.put,
      `/paciente/anamnese/`,
      payload.rowbody
    );
    const { records } = data;
    if (records.error) {
      yield put(UserActions.saveGrupoAnamneseFailure(records.msg));
    } else {
      yield put(UserActions.saveGrupoAnamneseSuccess(records.msg));
    }
  } catch (err) {
    yield put(
      UserActions.saveGrupoAnamneseFailure(
        'Erro interno no módulo de salvar os dados da anamnese!'
      )
    );
  }
}

export function* getPermissions(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/paciente/verificar-permissoes/${payload.id}`
    );

    if (data.records.error) {
      yield put(UserActions.getPermissionsFailure(data.records.msg));
    } else {
      const { records } = data;
      yield put(UserActions.getPermissionsSuccess(records.data));
    }
  } catch (err) {
    yield put(
      UserActions.getPermissionsFailure(
        'Erro interno no módulo de recolher as permissões de acesso do paciente!'
      )
    );
  }
}
