/* eslint-disable class-methods-use-this */
import M from 'materialize-css';
import React, { Component } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import {
  AppBar,
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import DateRageIcon from '@material-ui/icons/DateRange';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import {
  BoxButtons,
  BoxInformation,
  BoxContentSchedules,
  BoxHeaderSchedules,
  BoxToolbar,
  BoxInputs,
  Wrapper,
} from './styles';

import api from '../../services/api';
import 'moment/locale/pt-br';

import Config from '../../config';
import Factories from '../../factories';

const TextMaskCpfCustom = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      showMask
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
      ref={() => {
        inputRef(inputRef ? inputRef.inputElement : null);
      }}
    />
  );
};

const PhoneMaskCpfCustom = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      showMask
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
      ref={() => {
        inputRef(inputRef ? inputRef.inputElement : null);
      }}
    />
  );
};

const TextMaskCepCustom = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      showMask
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      guide={false}
      ref={() => {
        inputRef(inputRef ? inputRef.inputElement : null);
      }}
    />
  );
};

TextMaskCpfCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

PhoneMaskCpfCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskCepCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Psychologist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      loading: true,
      loadingModal: false,
      horarios: [],
      servicos: [],
      metodoPgto: null,
      pagamento: null,
      profissional: {},
      schedule: null,
      dtDefault: null,
      information: null,
      formCartao: false,
      modalPgto: false,
      modalForm: false,
      modalAlert: false,
      modalTitle: '',
      message: '',
      cpf: '',
      nome: '',
      email: '',
      notas: '',
      telefone: '',
      servico: 0,
      formaPgto: 0,
      step: 0,
      cartao: {
        portador_nome: '',
        portador_numero: '',
        portador_validade_mes: 0,
        portador_validade_ano: 0,
        portador_cod_seguranca: '',
      },
    };
  }

  componentDidMount() {
    Moment.locale('pt-br');

    const { match } = this.props;
    const { params } = match;

    document.querySelector('body').classList.add('bg-grey');
    setTimeout(() => {
      this.loadProfissional(params);
    }, 2000);
  }

  getOutlineInput = () => (
    <OutlinedInput labelWidth={60} name="servico" id="servico" />
  );

  getOutlineInputFormaPgto = () => (
    <OutlinedInput labelWidth={60} name="forma-pgto" id="forma-pgto" />
  );

  getOutlineInputPortadorValidadeMes = () => (
    <OutlinedInput
      labelWidth={60}
      name="portador_validade_mes"
      id="portador_validade_mes"
    />
  );

  getOutlineInputPortadorValidadeAno = () => (
    <OutlinedInput
      labelWidth={60}
      name="portador_validade_ano"
      id="portador_validade_ano"
    />
  );

  getDayOfWeek(item) {
    const d = item.split('/');
    return Moment(`${d[2]}-${d[1]}-${d[0]}`).format('dddd');
  }

  getDates(horarios) {
    const dates = Object.keys(horarios);
    return dates;
  }

  getShedules(horarios) {
    const arr = [];
    const dates = Object.keys(horarios);

    let count = 0;
    dates.forEach(i => {
      const items = horarios[i];
      count += 1;

      if (items.length === 0) {
        arr[count] = [];
      }

      items.forEach(value => {
        if (!arr[count]) {
          arr[count] = [];
        }
        arr[count].push(`${i}#${value}`);
      });
    });

    return arr;
  }

  handleOpenModalAgendamento = item => {
    const { profissional } = this.state;
    if (profissional.flg_cobranca) {
      const hasCobranca = parseInt(profissional.flg_cobranca, 10);
      if (hasCobranca) {
        this.setState({
          schedule: item,
          modalPgto: true,
        });
        return false;
      }
    }

    this.setState({
      schedule: item,
      modalForm: true,
    });

    return true;
  };

  handleOpenModalInformation = (type, info) => {
    this.setState({
      modalTitle: type,
      information: info,
      modalAlert: true,
    });
    return true;
  };

  handleClose = () => {
    this.setState({
      modalPgto: false,
      modalForm: false,
      modalAlert: false,
      formCartao: false,
    });

    setTimeout(() => {
      this.setState({
        step: 0,
        servico: 0,
        formaPgto: 0,
        cpf: '',
        nome: '',
        email: '',
        notas: '',
        telefone: '',
        cartao: {
          portador_nome: '',
          portador_numero: '',
          portador_validade_mes: '',
          portador_validade_ano: '',
          portador_cod_seguranca: '',
        },
      });
    }, 1000);
  };

  handleNext = e => {
    e.preventDefault();
    const { servico } = this.state;
    if (servico <= 0) {
      M.toast({
        html: 'Serviço é um campo de preenchimento obrigatório!',
        displayLength: 5000,
      });
      return false;
    }

    this.setState({ step: 1 });
    return false;
  };

  handleBack = () => {
    this.setState({ step: 0 });
    return false;
  };

  formatDate = date => {
    const d = date.split('/');
    return `${d[0]}/${d[1]}`;
  };

  getCardProfissional = profissional => {
    const { message } = this.state;

    if (profissional.nome_usuario_site) {
      return (
        <Grid item xs={12} sm={8}>
          <BoxInformation>
            <Grid item xs={12} sm={12}>
              {profissional.img_avatar ? (
                <Avatar
                  alt={profissional.nome_usuario_site}
                  className="avatar"
                  src={`${Config.URL_IMAGE}${profissional.img_avatar}`}
                />
              ) : (
                <Avatar
                  alt={profissional.nome_usuario_site}
                  className="avatar"
                  src={require('../../assets/img/user.png')}
                />
              )}
            </Grid>
            <Grid className="name" item xs={12} sm={12}>
              {/*
                <Typography variant="h6" color="inherit">
                  PROFISSIONAL
                </Typography>
              */}
              <Typography variant="h4" color="inherit">
                {profissional.nome_usuario_site}
              </Typography>
              <p>{profissional.sobre_mim}</p>
              <Typography
                variant="h4"
                color="inherit"
                style={{ marginTop: 20 }}
              >
                Formação Acadêmica
              </Typography>
              <p>{profissional.form_academ}</p>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <BoxButtons>
                  <Button
                    className="btn-primary"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.handleOpenModalInformation(
                        'Telefone',
                        profissional.tel_site
                      );
                    }}
                  >
                    Ver telefone
                    <PhoneIcon className="btn-icon" />
                  </Button>
                </BoxButtons>
              </Grid>
              <Grid item xs={12} sm={6}>
                <BoxButtons>
                  <Button
                    className="btn-primary"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.handleOpenModalInformation(
                        'E-mail',
                        profissional.email
                      );
                    }}
                  >
                    Ver e-mail
                    <EmailIcon className="btn-icon" />
                  </Button>
                </BoxButtons>
              </Grid>
            </Grid>
          </BoxInformation>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} sm={8}>
        <BoxInformation>
          <Grid item xs={12} sm={12}>
            <Grid container className="box-message">
              <HighlightOffIcon className="icon-message-danger" />
            </Grid>
            <h4 className="text-message">{message}</h4>
          </Grid>
        </BoxInformation>
      </Grid>
    );
  };

  getCoverImage = professional => {
    if (professional.img_capa) {
      const imgCapa = professional.img_capa;
      if (imgCapa.trim() !== '') {
        return (
          <div
            className="bg-parallax-001"
            style={{
              backgroundImage: `url(${Config.URL_IMAGE}${imgCapa})`,
              transform: 'translate3d(0px, 0px, 0px)',
            }}
          />
        );
      }
    }

    return (
      <div
        className="bg-parallax-001"
        style={{
          backgroundColor: '#DBDBDB',
        }}
      />
    );
  };

  getAvatar = professional => {
    if (professional.img_avatar) {
      return (
        <Avatar
          alt={professional.nome_usuario_site}
          className="avatar"
          src={`${Config.URL_IMAGE}${professional.img_avatar}`}
        />
      );
    }

    return (
      <Avatar
        alt="No avatar"
        className="avatar"
        src={require('../../assets/img/no-user.jpg')}
      />
    );
  };

  renderModalLoading = () => (
    <Grid
      container
      style={{
        padding: 20,
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Grid>
  );

  renderValorServico = servico => {
    const { profissional } = this.state;
    if (profissional.flg_cobranca === '1') {
      const valor = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(servico.valor_padrao_serv);
      return `(${valor})`;
    }
    return null;
  };

  renderFormAgendamento = () => {
    const { servicos, email, nome, notas, servico } = this.state;
    const { telefone } = this.state;
    return (
      <>
        {servicos.length > 0 && (
          <BoxInputs>
            <FormControl
              required
              variant="outlined"
              style={{ marginTop: 18, marginBottom: 8 }}
            >
              <InputLabel
                htmlFor="servico"
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
              >
                Serviço
              </InputLabel>
              <Select
                value={servico}
                input={this.getOutlineInput()}
                onChange={e => this.onChangeServico(e)}
              >
                <MenuItem value={0}>
                  <em style={{ fontSize: 11, color: '#5c5c5c' }}>
                    -- ESCOLHA UM SERVIÇO --
                  </em>
                </MenuItem>
                {servicos.map(item => (
                  <MenuItem key={item.cod_servico} value={item.cod_servico}>
                    {item.nome_servico} {this.renderValorServico(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </BoxInputs>
        )}
        <BoxInputs>
          <TextField
            id="email"
            label="E-mail"
            type="email"
            name="email"
            autoComplete="email"
            margin="normal"
            variant="outlined"
            required
            value={email}
            onChange={e => {
              this.setState({ email: e.target.value });
            }}
          />
        </BoxInputs>
        <BoxInputs>
          <TextField
            id="nome"
            label="Nome Completo"
            type="text"
            name="nome"
            margin="normal"
            variant="outlined"
            required
            value={nome}
            onChange={e => {
              this.setState({ nome: e.target.value });
            }}
          />
        </BoxInputs>
        <BoxInputs>
          <TextField
            id="telefone"
            label="Telefone"
            name="telefone"
            margin="normal"
            variant="outlined"
            required
            InputProps={{
              inputComponent: PhoneMaskCpfCustom,
              value: telefone,
              onChange: this.handleChange('telefone'),
            }}
          />
        </BoxInputs>
        <BoxInputs>
          <TextField
            id="notas"
            label="Observações"
            rowsMax="4"
            margin="normal"
            variant="outlined"
            multiline
            value={notas}
            onChange={e => {
              this.setState({ notas: e.target.value });
            }}
          />
        </BoxInputs>
      </>
    );
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  getNaoConfigurouCobranca = () => {
    const { profissional } = this.state;
    if (profissional.flg_cobranca === '1') {
      return profissional.nao_configurou_cob;
    }
    return true;
  };

  onChangeServico = e => {
    const { value } = e.target;
    const { servicos, profissional } = this.state;

    if (profissional.flg_cobranca) {
      const hasCobranca = parseInt(profissional.flg_cobranca, 10);
      if (hasCobranca) {
        let modalForm = false;
        servicos.forEach(item => {
          if (item.cod_servico === value) {
            const valor = parseFloat(item.valor_padrao_serv);
            if (valor <= 0) {
              modalForm = true;
            }
          }
        });
        this.setState({
          servico: value,
          modalForm,
          modalPgto: !modalForm,
        });
      }
    }

    this.setState({ servico: value });
  };

  onChangeFormaPgto = e => {
    const { value } = e.target;
    const isCartao = value === 3;
    this.setState({
      formaPgto: value,
      formCartao: isCartao,
    });
  };

  onChangePortadorCartao = e => {
    const { value, name } = e.target;
    this.setState(prevState => ({
      cartao: {
        ...prevState.cartao,
        [name]: value,
      },
    }));
  };

  async pageHorarios(type) {
    const { profissional, horarios, dtDefault } = this.state;
    const dates = Object.keys(horarios);

    const dtInitial = Factories.formatDateViewToBD(dates[0]);
    let dtFinal = Factories.formatDateViewToBD(dates[dates.length - 1]);

    if (type === 'prev') {
      const dtObjInitial = Factories.getSimpleDateObjectFromString(dtInitial);
      dtObjInitial.setDate(dtObjInitial.getDate() - 4);

      const dtObjDefault = Factories.getSimpleDateObjectFromString(dtDefault);
      if (dtObjInitial < dtObjDefault) {
        M.toast({
          html: 'Não se pode efetuar agendamentos para datas passadas!',
          displayLength: 3000,
        });
        return false;
      }

      const dtObjFinal = Factories.getSimpleDateObjectFromString(dtFinal);
      dtObjFinal.setDate(dtObjFinal.getDate() - 8);

      dtFinal = Factories.getDateFromObject(dtObjFinal);
    }

    const response = await api.get(
      `/site-busca/listar-horarios-disp/?q=(emp:${profissional.cod_empresa},start_date:${dtInitial},end_date:${dtFinal})`
    );

    this.setState({ horarios: response.data.records });

    return false;
  }

  async loadProfissional(params) {
    const { id } = params;
    const responseOne = await api.get(
      `/site-busca/get-profissional-site/?q=(prof_url:${id},site:3)`
    );

    if (responseOne.data.records) {
      const profissional = responseOne.data.records;
      if (profissional.nome_usuario_site) {
        const { disponivel } = profissional;
        if (disponivel) {
          this.setState({
            profissional,
            loading: false,
            servicos: profissional.servico,
          });

          if (profissional.servico.length === 1) {
            this.setState({
              servico: profissional.servico[0].cod_servico,
            });
          }

          const responseTwo = await api.get(
            `/site-busca/listar-horarios-disp/?q=(emp:${profissional.cod_empresa})`
          );

          const horarios = responseTwo.data.records;
          const dtDefault = Factories.formatDateViewToBD(
            Object.keys(horarios)[0]
          );
          this.setState({
            horarios,
            dtDefault,
          });
        } else {
          this.setState({
            loading: false,
            message:
              'Este profissional não tem permissão para solicitação de agendamentos!',
          });
        }
      } else {
        this.setState({
          loading: false,
          message: 'Nenhum registro encontrado para o profissional em questão!',
        });
      }
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    const {
      cpf,
      email,
      nome,
      telefone,
      notas,
      profissional,
      schedule,
      servico,
      formaPgto,
      formCartao,
      servicos,
      cartao,
    } = this.state;

    let flag = true;
    if (servicos.length > 0) {
      if (servico <= 0) {
        flag = false;
        M.toast({
          html: 'Serviço é um campo de preenchimento obrigatório!',
          displayLength: 5000,
        });
      }
    }

    let hasCobranca = 0;
    let modalForm = false;

    if (profissional.flg_cobranca) {
      hasCobranca = parseInt(profissional.flg_cobranca, 10);
      if (hasCobranca) {
        servicos.forEach(item => {
          if (item.cod_servico === servico) {
            const valor = parseFloat(item.valor_padrao_serv);
            if (valor <= 0) {
              modalForm = true;
            }
          }
        });

        if (!modalForm) {
          if (cpf.length !== 14) {
            flag = false;
            M.toast({
              html: 'Verifique o CPF pois o mesmo se encontra inválido!',
              displayLength: 5000,
            });
          }

          if (formaPgto <= 0) {
            flag = false;
            M.toast({
              html: 'Forma de pagamento é um campo obrigatório!',
              displayLength: 5000,
            });
          }
        }
      }
    }

    const agendamento = {
      datahora_solicitada: schedule.replace('#', ' '),
      nome_pac_ag: nome,
      tel_pac_ag: telefone,
      email_pac_ag: email,
      notas_pac_ag: notas,
      cod_empresa: profissional.cod_empresa,
      cod_clinica: profissional.cod_clinica,
    };

    if (servico) {
      agendamento.cod_servico = servico;
    }

    if (hasCobranca && !modalForm) {
      agendamento.cpf = cpf;
      agendamento.forma_pgto = formaPgto;
    }

    if (formCartao) {
      agendamento.cartao = cartao;
    }

    if (flag) {
      this.setState({ loadingModal: true });
      const response = await api.post(
        '/site-busca/agendar-paciente',
        agendamento
      );

      if (!response.data.records.error) {
        this.setState({ loadingModal: false, schedule: null });
        this.handleClose();
        setTimeout(() => {
          this.setState({
            success: true,
            pagamento: response.data.records.pgto,
            metodoPgto: response.data.records.method,
          });
        }, 1000);
      } else {
        this.setState({ loadingModal: false });
        M.toast({
          html: response.data.records.msg,
          displayLength: 3000,
        });
      }
    }

    return false;
  }

  renderFormPagamento = () => {
    const { step, formaPgto, formCartao, cartao, profissional } = this.state;
    if (step === 0) {
      return this.renderFormAgendamento();
    }

    if (
      profissional.cobranca_formapgto === '' ||
      profissional.cobranca_formapgto === null ||
      profissional.cobranca_formapgto === undefined
    ) {
      return (
        <p className="note-message">
          O profissional escolhido ainda não configurou as formas de pagamento
          aceitas para o agendamento on-line, entre em contato com o mesmo para
          verificar esta situação!
        </p>
      );
    }

    const formasPagamento = profissional.cobranca_formapgto.split('|');
    const { cpf } = this.state;
    return (
      <>
        <BoxInputs>
          <TextField
            id="cpf"
            label="CPF"
            name="cpf"
            margin="normal"
            variant="outlined"
            required
            InputProps={{
              inputComponent: TextMaskCpfCustom,
              value: cpf,
              onChange: this.handleChange('cpf'),
            }}
          />
        </BoxInputs>
        <BoxInputs>
          <FormControl
            required
            variant="outlined"
            style={{ marginTop: 18, marginBottom: 8 }}
          >
            <InputLabel
              htmlFor="forma-pgto"
              ref={ref => {
                this.InputLabelRef = ref;
              }}
            >
              Forma de Pagamento
            </InputLabel>
            <Select
              value={formaPgto}
              input={this.getOutlineInputFormaPgto()}
              onChange={e => this.onChangeFormaPgto(e)}
              className="psico-fieldset-select-pgto"
            >
              <MenuItem value={0}>
                <em style={{ fontSize: 11, color: '#5c5c5c' }}>
                  -- ESCOLHA A FORMA DE PAGAMENTO --
                </em>
              </MenuItem>
              {formasPagamento.indexOf('8') === -1 ? null : (
                <MenuItem value={8}>Boleto Bancário</MenuItem>
              )}
              {formasPagamento.indexOf('3') === -1 ? null : (
                <MenuItem value={3}>Cartão de Crédito</MenuItem>
              )}
              {profissional.pix_ativo &&
              formasPagamento.indexOf('12') === -1 ? null : (
                <MenuItem value={12}>PIX</MenuItem>
              )}
            </Select>
          </FormControl>
        </BoxInputs>
        {formCartao && (
          <>
            <h6>Dados do Cartão:</h6>
            <BoxInputs>
              <TextField
                id="portador_nome"
                label="Nome no Cartão"
                type="text"
                name="portador_nome"
                margin="normal"
                variant="outlined"
                required
                value={cartao.portador_nome}
                onChange={e => this.onChangePortadorCartao(e)}
              />
            </BoxInputs>
            <BoxInputs>
              <TextField
                id="portador_numero"
                label="Número do Cartão"
                type="text"
                name="portador_numero"
                margin="normal"
                variant="outlined"
                required
                value={cartao.portador_numero}
                onChange={e => this.onChangePortadorCartao(e)}
              />
            </BoxInputs>
            <BoxInputs>
              <FormControl
                required
                variant="outlined"
                style={{ marginTop: 18, marginBottom: 8 }}
              >
                <InputLabel
                  htmlFor="portador_validade_mes"
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                >
                  Data de Expiração (Mês)
                </InputLabel>
                <Select
                  value={cartao.portador_validade_mes}
                  input={this.getOutlineInputPortadorValidadeMes()}
                  onChange={e => this.onChangePortadorCartao(e)}
                  className="psico-fieldset-select-expiracao"
                >
                  <MenuItem value={0}>
                    <em style={{ fontSize: 11, color: '#5c5c5c' }}>
                      -- ESCOLHA O MÊS DE VENCIMENTO --
                    </em>
                  </MenuItem>
                  <MenuItem value="01">01</MenuItem>
                  <MenuItem value="02">02</MenuItem>
                  <MenuItem value="03">03</MenuItem>
                  <MenuItem value="04">04</MenuItem>
                  <MenuItem value="05">05</MenuItem>
                  <MenuItem value="06">06</MenuItem>
                  <MenuItem value="07">07</MenuItem>
                  <MenuItem value="08">08</MenuItem>
                  <MenuItem value="09">09</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="11">11</MenuItem>
                  <MenuItem value="12">12</MenuItem>
                </Select>
              </FormControl>
            </BoxInputs>
            <BoxInputs>
              <FormControl
                required
                variant="outlined"
                style={{ marginTop: 18, marginBottom: 8 }}
              >
                <InputLabel
                  htmlFor="portador_validade_ano"
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                >
                  Data de Expiração (Ano)
                </InputLabel>
                <Select
                  value={cartao.portador_validade_ano}
                  input={this.getOutlineInputPortadorValidadeAno()}
                  onChange={e => this.onChangePortadorCartao(e)}
                  className="psico-fieldset-select-expiracao"
                >
                  <MenuItem value={0}>
                    <em style={{ fontSize: 11, color: '#5c5c5c' }}>
                      -- ESCOLHA O ANO DE VENCIMENTO --
                    </em>
                  </MenuItem>
                  <MenuItem value="2020">2020</MenuItem>
                  <MenuItem value="2021">2021</MenuItem>
                  <MenuItem value="2022">2022</MenuItem>
                  <MenuItem value="2023">2023</MenuItem>
                  <MenuItem value="2024">2024</MenuItem>
                  <MenuItem value="2025">2025</MenuItem>
                  <MenuItem value="2026">2026</MenuItem>
                  <MenuItem value="2027">2027</MenuItem>
                  <MenuItem value="2028">2028</MenuItem>
                  <MenuItem value="2029">2029</MenuItem>
                  <MenuItem value="2030">2030</MenuItem>
                </Select>
              </FormControl>
            </BoxInputs>
            <BoxInputs>
              <TextField
                id="portador_cod_seguranca"
                label="Código de Verificação"
                type="text"
                name="portador_cod_seguranca"
                margin="normal"
                variant="outlined"
                required
                value={cartao.portador_cod_seguranca}
                onChange={e => this.onChangePortadorCartao(e)}
              />
            </BoxInputs>
          </>
        )}
      </>
    );
  };

  renderButtonsPagamento = () => {
    const { step } = this.state;
    if (step === 0) {
      return (
        <>
          <Button
            component="a"
            color="primary"
            onClick={() => {
              this.handleClose();
            }}
          >
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            Próximo
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          component="a"
          color="primary"
          onClick={() => {
            this.handleClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          component="a"
          color="primary"
          onClick={() => {
            this.handleBack();
          }}
        >
          Voltar
        </Button>
        <Button
          component="a"
          color="primary"
          onClick={e => {
            this.handleSubmit(e);
          }}
        >
          Salvar
        </Button>
      </>
    );
  };

  renderMessagePagamento = () => {
    const { step } = this.state;
    if (step === 0) {
      return (
        <small>
          Preencha o formulário abaixo para efetuar a solicitação de
          agendamento.
        </small>
      );
    }

    return <small>Dados necessários para geração do pagamento.</small>;
  };

  renderRetornoPagamento = () => {
    const { pagamento, metodoPgto } = this.state;
    if (pagamento && metodoPgto) {
      switch (metodoPgto) {
        case 'bank_slip':
          return (
            <Button
              style={{ marginTop: 10 }}
              variant="contained"
              color="primary"
              href={pagamento.data.url}
            >
              Imprimir Boleto
            </Button>
          );

        case 'credit_card':
          return null;

        case 'pix':
          return (
            <>
              <p className="note-message" style={{ marginTop: 10 }}>
                <h4 className="text-message">O que é o Pix?</h4>
                <p className="note-message">
                  É a nova modalidade de transferências do banco central, que
                  funciona 24 horas por dia e possui confirmação em tempo real.
                </p>
                <p className="note-message">
                  Para efetuar o pagamento procure em seu aplicativo do banco ou
                  conta digital a funcionalidade e escaneie o Qr Code ao lado,
                  ou copie o código usando o botão abaixo.
                </p>
              </p>
              <div style={{ marginTop: 10 }}>
                <img
                  alt="PIX"
                  className="psico-qr-code"
                  src={pagamento.data.pix.qrcode}
                />
              </div>
              <Button
                style={{ marginTop: 10 }}
                variant="contained"
                color="primary"
                href={pagamento.data.secure_url}
              >
                Imprimir PIX
              </Button>
            </>
          );

        default:
          return null;
      }
    }

    return null;
  };

  render() {
    const {
      success,
      loading,
      loadingModal,
      horarios,
      pagamento,
      profissional,
      information,
      schedule,
      modalPgto,
      modalForm,
      modalAlert,
      modalTitle,
    } = this.state;

    const dates = this.getDates(horarios);
    const schedules = this.getShedules(horarios);

    return (
      <div style={{ background: '#dbdbdb' }}>
        {loading ? (
          <Grid
            container
            style={{
              padding: 20,
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <AppBar position="static">
              <BoxToolbar>
                <Toolbar
                  variant="dense"
                  style={{ minHeight: '56px', lineHeight: '56px' }}
                >
                  <IconButton edge="start" color="inherit" aria-label="menu">
                    <DateRageIcon />
                  </IconButton>
                  <Typography variant="h6" color="inherit">
                    Agendamento Online
                  </Typography>
                </Toolbar>
              </BoxToolbar>
            </AppBar>
            {this.getCoverImage(profissional)}
            <Wrapper>
              <Grid container spacing={3} style={{ padding: 20 }}>
                {this.getCardProfissional(profissional)}
                {success ? (
                  <Grid item xs={12} sm={4}>
                    <BoxInformation>
                      <Grid container className="box-message">
                        <EventAvailableIcon className="icon-message-success" />
                      </Grid>
                      <h4 className="text-message">
                        Agendamento solicitado com sucesso!
                      </h4>
                      <p className="note-message">
                        Aguarde até receber a confirmação ou rejeição do
                        agendamento!
                      </p>
                      {pagamento && this.renderRetornoPagamento()}
                    </BoxInformation>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4}>
                    <BoxInformation>
                      <Grid container>
                        <Grid className="titulo" item xs={12} sm={12}>
                          <Typography variant="h4" color="inherit">
                            Agendar Consulta
                          </Typography>
                        </Grid>
                        <Grid className="titulo" item xs={12} sm={12}>
                          {schedules.length ? (
                            <table className="responsive tbl-actions">
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: 'left' }}>
                                    <i className="material-icons">
                                      chevron_left
                                    </i>
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        this.pageHorarios('prev');
                                      }}
                                    >
                                      Anterior
                                    </Button>
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        this.pageHorarios('next');
                                      }}
                                    >
                                      Próximo
                                    </Button>
                                    <i className="material-icons">
                                      chevron_right
                                    </i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ) : (
                            <p className="note-message">
                              Este profissional não contém horários disponíveis
                              para solicitação de agendamentos!
                            </p>
                          )}

                          <BoxHeaderSchedules>
                            {dates.map(date => (
                              <BoxContentSchedules key={Math.random()}>
                                <div className="td-dia-semana">
                                  {this.getDayOfWeek(date).substr(0, 3)}
                                </div>
                              </BoxContentSchedules>
                            ))}
                          </BoxHeaderSchedules>

                          <BoxHeaderSchedules>
                            {dates.map(date => (
                              <BoxContentSchedules key={Math.random()}>
                                <div className="td-dia-mes">
                                  {this.formatDate(date)}
                                </div>
                              </BoxContentSchedules>
                            ))}
                          </BoxHeaderSchedules>

                          {schedules.map(itemSchedule => (
                            <BoxContentSchedules key={Math.random()}>
                              {itemSchedule.length ? (
                                itemSchedule.map(item => (
                                  <div key={Math.random()} className="td-btn">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      className="btn-table"
                                      onClick={() => {
                                        this.handleOpenModalAgendamento(item);
                                      }}
                                      color={
                                        item === schedule
                                          ? 'secondary'
                                          : 'inherit'
                                      }
                                    >
                                      {item.split('#')[1]}
                                    </Button>
                                  </div>
                                ))
                              ) : (
                                <div key={Math.random()} className="td-btn">
                                  <small>Nenhum horário!</small>
                                </div>
                              )}
                            </BoxContentSchedules>
                          ))}
                        </Grid>
                      </Grid>
                    </BoxInformation>
                  </Grid>
                )}
              </Grid>
            </Wrapper>

            <div className="box-modal-formulario">
              <Dialog
                className="modal-schedule"
                aria-labelledby="form-dialog-title"
                open={modalForm}
                disableEscapeKeyDown
                disableBackdropClick
                onClose={() => {
                  this.handleClose();
                }}
              >
                <form
                  autoComplete="on"
                  onSubmit={e => {
                    this.handleSubmit(e);
                  }}
                >
                  <DialogTitle id="form-dialog-title">Agendamento</DialogTitle>
                  <DialogContent>
                    {loadingModal ? (
                      <>{this.renderModalLoading()}</>
                    ) : (
                      <>
                        <DialogContentText style={{ marginBottom: 0 }}>
                          <small>
                            Preencha o formulário abaixo para efetuar a
                            solicitação de agendamento.
                          </small>
                        </DialogContentText>
                        {this.renderFormAgendamento()}
                      </>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      component="a"
                      color="primary"
                      onClick={() => {
                        this.handleClose();
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </div>

            <div className="box-modal-pagamento">
              <Dialog
                aria-labelledby="form-dialog-title-pgto"
                className="modal-schedule"
                open={modalPgto}
                disableEscapeKeyDown
                disableBackdropClick
                onClose={() => {
                  this.handleClose();
                }}
              >
                <form
                  autoComplete="on"
                  onSubmit={e => {
                    this.handleNext(e);
                  }}
                >
                  <DialogTitle id="form-dialog-title-pgto">
                    Agendamento
                  </DialogTitle>
                  <DialogContent>
                    {loadingModal ? (
                      <>{this.renderModalLoading()}</>
                    ) : (
                      <>
                        {this.getNaoConfigurouCobranca() ? (
                          <p className="note-message">
                            O profissional escolhido ainda não pode emitir
                            cobranças, entre em contato com o mesmo para
                            verificar esta situação!
                          </p>
                        ) : (
                          <>
                            <DialogContentText style={{ marginBottom: 0 }}>
                              {this.renderMessagePagamento()}
                            </DialogContentText>
                            {this.renderFormPagamento()}
                          </>
                        )}
                      </>
                    )}
                  </DialogContent>
                  <DialogActions>{this.renderButtonsPagamento()}</DialogActions>
                </form>
              </Dialog>
            </div>

            <div className="box-modal-loader">
              <Dialog
                aria-labelledby="alert-dialog-title-loader"
                aria-describedby="alert-dialog-description-loader"
                open={modalAlert}
                disableEscapeKeyDown
                disableBackdropClick
                onClose={() => {
                  this.setState({ modalAlert: false });
                }}
              >
                <DialogTitle id="alert-dialog-title-loader">
                  {modalTitle}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description-loader">
                    <span style={{ fontSize: '12px' }}>
                      {information || 'Não informado.'}
                    </span>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    onClick={() => {
                      this.setState({ modalAlert: false });
                    }}
                  >
                    Fechar
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </>
        )}
      </div>
    );
  }
}

Psychologist.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Psychologist;
