import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

import { NavLink } from 'react-router-dom';

import './styles.css';
import Factories from '../../factories';

const Footer = ({ diary }) => (
  <footer className="hide-on-large-only grey lighten-4">
    <div>
      <ul>
        {!diary ? (
          <li>
            <NavLink to="/diary" activeClassName="active">
              <i className="material-icons">mood</i>
              <small>{intl.get('DIARIO')}</small>
            </NavLink>
          </li>
        ) : null}

        <li>
          <NavLink to="/consultation" activeClassName="active">
            <i className="material-icons">event</i>
            <small>{intl.get('SESSOES')}</small>
          </NavLink>
        </li>

        {Factories.getVerificaPermissao(7) ? (
          <li>
            <NavLink to="/payment" activeClassName="active">
              <i className="material-icons">credit_card</i>
              <small>{intl.get('PAGAMENTOS')}</small>
            </NavLink>
          </li>
        ) : null}

        {/* <li>
          <NavLink to="/tasks" activeClassName="active">
            <i className="material-icons">assignment_turned_in</i>
            <small>{intl.get('TAREFAS')}</small>
          </NavLink>
        </li> */}

        <li>
          <NavLink to="/config" activeClassName="active">
            <i className="material-icons">settings</i>
            <small>{intl.get('OPCOES')}</small>
          </NavLink>
        </li>
      </ul>
    </div>
  </footer>
);

Footer.propTypes = {
  diary: PropTypes.bool,
};

Footer.defaultProps = {
  diary: true,
};

export default Footer;
