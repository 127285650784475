import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 3;
  position: relative;
  margin-top: -100px;
`;

export const BoxSchedule = styled.div`
  z-index: 3;
  position: relative;
  margin-top: -140px;
`;

export const BoxContentSchedules = styled.div`
  width: 20%;
  display: block;
  float: left;
  margin: 0;
  padding: 0;
`;

export const BoxHeaderSchedules = styled.div`
  border-bottom: 1px solid #e2e2e2;
  display: block;
  width: 100%;
  float: left;
`;

export const ButtonAdd = styled.button`
  bottom: 80px;
  cursor: pointer;
  height: 50px;
  right: 20px;
  line-height: 65px;
  position: fixed;
  width: 50px;
`;

export const BoxToolbar = styled.div`
  color: #555;
  border: 0;
  box-shadow: 0px -20px 20px 10px rgba(0, 0, 0, 0),
    0px -20px 10px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff !important;

  h6 {
    color: inherit;
    padding: 8px 16px;
    font-size: 18px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 30px;
    border-radius: 3px;
    letter-spacing: unset;
    text-transform: none;
  }
`;

export const BoxInformation = styled.div`
  align-items: center;
  align-content: center;
  background: #ffffff;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  padding: 20px 10px;

  .text-message {
    color: #3c4858;
    display: inline-block;
    font-size: 1.2em;
    position: relative;
    margin-top: 10px;
    margin-bottom: 0;
    min-height: 32px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    text-align: center;
  }

  .note-message {
    color: #7e7d7d;
    font-size: 0.865rem;
    padding: 6px 30px;
    margin-top: 8px;
    text-align: center;
  }

  .avatar {
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
      0 8px 10px -5px rgba(0, 0, 0, 0.2);
    transform: translate3d(0, -60%, 0);
    height: 110px;
    width: 110px;
  }

  .name {
    margin-top: -50px;
    text-align: center;
  }

  .titulo {
    text-align: center;
  }

  .name h4,
  .titulo h4 {
    color: #3c4858;
    display: inline-block;
    font-size: 1.3em;
    position: relative;
    margin-top: 5px;
    min-height: 32px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
  }

  .name h6 {
    color: #7e7d7d;
    margin-top: 10px;
    font-size: 0.6em;
    text-align: center;
  }

  .name p {
    color: #7e7d7d;
    font-size: 0.875rem;
    padding: 5px 20px;
  }

  .box-message {
    justify-content: center;
  }

  .icon-message-success {
    color: #1eac14;
    font-size: 3.5rem;
  }

  .icon-message-danger {
    color: #b30404;
    font-size: 3.5rem;
  }
`;

export const TextInfo = styled.div`
  align-items: center;
  align-content: center;
  padding-top: 5px;
  text-align: center;

  .text-icon {
    color: #3c4858;
    font-size: 1rem;
    margin-left: 8px;
    top: 2px;
    position: relative;
  }

  span {
    color: #3c4858;
    font-size: 0.875rem;
    padding: 5px;
  }
`;

export const BoxButtons = styled.div`
  align-items: center;
  align-content: center;
  padding-top: 20px;
  text-align: center;

  .btn-icon {
    font-size: 1rem;
    margin-left: 8px;
  }
`;

export const BoxInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .psico-fieldset-select-pgto legend {
    width: 125px !important;
  }

  .psico-fieldset-select-expiracao legend {
    width: 135px !important;
  }
`;
