import { call, put } from 'redux-saga/effects';

import { Creators as SheduleActions } from '../ducks/schedules';

import API from '../../services/api';

export function* listSchedules() {
  try {
    const { data } = yield call(API.get, '/agenda/horarios-disp-paciente/');
    if (!data.records.length) {
      yield put(
        SheduleActions.listSchedulesFailure('Nenhum horário cadastrado!')
      );
    } else {
      const { records } = data;
      yield put(SheduleActions.listSchedulesSuccess(records));
    }
  } catch (err) {
    yield put(
      SheduleActions.listSchedulesFailure(
        'Erro interno no módulo de listagem de horários!'
      )
    );
  }
}

export function* reschedulePatient(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.post,
      '/agenda/reagendar-paciente/',
      payload.object
    );
    const { records } = data;
    if (records.error) {
      yield put(SheduleActions.reschedulePatientFailure(records.msg));
    } else {
      const message =
        typeof records.msg === 'string' ? records.msg : records.msg[0];
      yield put(SheduleActions.reschedulePatientSuccess(message));
    }
  } catch (err) {
    yield put(
      SheduleActions.reschedulePatientFailure(
        'Erro interno no módulo de reagendamento de horário!'
      )
    );
  }
}
