import axios from 'axios';

import Config from '../config';

import { getToken, hasToken, logout } from './storage';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? Config.URL_API_DEVELOPMENT
      : Config.URL_API_PRODUCTION,
});

const noToken = [
  '/psico-auth',
  '/site-busca/get-clinica/',
  '/site-busca/inserir-paciente',
  '/site-busca/listar-horarios-disp/',
  '/site-busca/get-profissional-site/',
  '/rotinas-gerais/redefinir-senha-pac',
  '/psico-auth/enviar-recuperar-senha-pac',
];

api.interceptors.request.use(async config => {
  if (
    config.url.includes('site-busca/get-clinica/') ||
    config.url.includes('site-busca/inserir-paciente') ||
    config.url.indexOf('/site-busca/listar-horarios-disp/') !== -1 ||
    config.url.indexOf('/site-busca/get-profissional-site/') !== -1
  ) {
    return config;
  }

  if (
    hasToken() === null &&
    config.url.indexOf('/site-busca/agendar-paciente') !== -1
  ) {
    return config;
  }

  if (!noToken.includes(config.url)) {
    const token = getToken();
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

/** Verificando a expiração do TOKEN para limpar storage e redirecionar para o LOGIN. */
api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      setTimeout(() => {
        logout();
        window.location.href = '/';
      }, 500);
    }
    return Promise.reject(error);
  }
);

export default api;
