import M from 'materialize-css';

import React, { Component } from 'react';

import Loading from '../../components/Loading';
import API from '../../services/api';

import './styles.css';

class esqueceu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: true,
      whiteLabel: '_psicomanager',
    };
  }

  componentDidMount() {
    const whiteLabel = localStorage.getItem('@white_label');
    this.setState({
      loading: false,
      whiteLabel,
    });
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });

    const { email } = this.state;
    if (!email) {
      M.toast({
        html: 'Preencha o campo de e-mail para continuar!',
        displayLength: 5000,
      });
      this.setState({ loading: false });
    } else {
      const response = await API.post(
        '/psico-auth/enviar-recuperar-senha-pac',
        {
          email_pac: email,
        }
      );

      if (!response.data.records.error) {
        this.setState({ loading: false });
        M.toast({
          html: response.data.records.msg,
          displayLength: 8000,
        });
      } else {
        this.setState({ loading: false });
        M.toast({
          html: response.data.records.msg,
          displayLength: 5000,
        });
      }
    }
  };

  onChangeEmail = async e => {
    this.setState({ email: e.target.value });
  };

  render() {
    const { loading, whiteLabel } = this.state;
    return loading ? (
      <Loading />
    ) : (
      <div id="login">
        <form onSubmit={this.handleSubmit}>
          {whiteLabel === '_psicomanager' ? (
            <>
              <img
                alt="PsicoManager Paciente"
                src={require('../../assets/img/icon.png')}
              />
              <h5>PsicoManager Paciente</h5>
              <span>Aproximando o profissional do paciente</span>
            </>
          ) : (
            <>
              <img
                alt="Terapia Interativa"
                src={require('../../assets/img/icon_terapiainterativa.png')}
                className="terapia-play"
              />
              <h5>Terapia Interativa</h5>
              <span>
                Plataforma que possibilita o uso de recursos terapêuticos em
                sessões de terapia online
              </span>
            </>
          )}
          <div id="esqueceu">
            <div className="input-field col s6">
              <label htmlFor="email">E-mail</label>
              <input
                id="email"
                aria-label="E-mail"
                type="email"
                className="validate"
                required="required"
                onChange={this.onChangeEmail}
              />
            </div>
            <button
              className="btn waves-light btn-large light-blue darken-3"
              type="submit"
            >
              <span>ENVIAR</span>
            </button>
            <div className="center-align voltar">
              <a href="/login" className="waves-effect waves-teal btn-flat">
                VOLTAR
              </a>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default esqueceu;
