/* eslint-disable camelcase */
let isSubscribed = false;
let swRegistration = null;

const applicationServerPublicKey =
  'BLSm8ST29VZq1m49vbOmZuaIKxcjzq1VkeGRvunipQ_FjUR2p52DEKyIJgKhGsZqP3bmg6-W0anaYdAGge37q_I';

const isIOS =
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

function getToken() {
  if (localStorage.getItem('@psico_pac')) {
    const { token } = JSON.parse(localStorage.getItem('@psico_pac'));
    return `Bearer ${token}`;
  }
  return '';
}

function saveUserSubscription(subscription) {
  // eslint-disable-next-line no-undef
  const request = new XMLHttpRequest();

  request.open(
    'POST',
    'https://apip.virtux.com.br/v1/1005/sistema/push/subscribe-patient'
  );
  request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  request.setRequestHeader('Authorization', getToken());

  // eslint-disable-next-line func-names
  request.onreadystatechange = function() {
    if (request.readyState !== 4) {
      return;
    }
    if (request.status !== 200 && request.status !== 304) {
      console.log(`Erro ao efetuar a inscrição do usuário: ${request.status}`);
    } else {
      console.log('Usuário inscrito com sucesso!');
    }
  };

  request.send(JSON.stringify(subscription));
}

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function getUserSubscription() {
  swRegistration.pushManager.getSubscription().then(subscription => {
    isSubscribed = !(subscription === null);
    console.log('Status da inscrição: ', isSubscribed);
    if (isSubscribed) {
      console.log('Usuário já se encontra inscrito!');
    } else {
      console.log('Efetuar a inscrição do usuário!');
      swRegistration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(applicationServerPublicKey),
        })
        .then(subscriptionResponse => {
          console.log('Dados da inscrição: ', subscriptionResponse);
          isSubscribed = true;
          saveUserSubscription(subscriptionResponse);
        })
        .catch(err => {
          console.log('Falha na inscrição do usuário ', err);
        });
    }
  });
}

function initializePushManager(registration) {
  if ('PushManager' in window) {
    swRegistration = registration;
    setTimeout(() => {
      getUserSubscription();
    }, 5000);
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      /* Inicializando as configurações de PushManager: */
      if (!isIOS && localStorage.getItem('@psico_pac')) {
        initializePushManager(registration);
      }

      // eslint-disable-next-line no-param-reassign
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              window.alert('Nova atualização disponivel!');

              setTimeout(() => {
                registration.unregister();
                window.location.reload(true);
              }, 5000);

              if (config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              console.log('Content is cached for offline use.');
              if (config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl)
    .then(response => {
      if (
        response.status === 404 ||
        response.headers.get('content-type').indexOf('javascript') === -1
      ) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://goo.gl/SC7cgQ'
          );
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
