/**
 * Types
 */
export const Types = {
  LIST_REQUEST: '@schedule/LIST_REQUEST',
  LIST_SUCCESS: '@schedule/LIST_SUCCESS',
  LIST_FAILURE: '@schedule/LIST_FAILURE',
  RESCHEDULE_PATIENT_REQUEST: '@schedule/RESCHEDULE_PATIENT_REQUEST',
  RESCHEDULE_PATIENT_SUCCESS: '@schedule/RESCHEDULE_PATIENT_SUCCESS',
  RESCHEDULE_PATIENT_FAILURE: '@schedule/RESCHEDULE_PATIENT_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  data: [],
  error: null,
  success: null,
  object: {},
};

export default function schedules(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state };

    case Types.RESCHEDULE_PATIENT_REQUEST:
      return { ...state, object: action.payload.object };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.payload.data,
      };

    case Types.RESCHEDULE_PATIENT_SUCCESS:
      return { ...state, error: null, success: action.payload.success };

    case Types.LIST_FAILURE:
    case Types.RESCHEDULE_PATIENT_FAILURE:
      return { ...state, error: action.payload.error, success: null };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listSchedulesRequest: () => ({
    type: Types.LIST_REQUEST,
  }),

  listSchedulesSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listSchedulesFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error },
  }),

  reschedulePatientRequest: object => ({
    type: Types.RESCHEDULE_PATIENT_REQUEST,
    payload: { object },
  }),

  reschedulePatientSuccess: success => ({
    type: Types.RESCHEDULE_PATIENT_SUCCESS,
    payload: { success },
  }),

  reschedulePatientFailure: error => ({
    type: Types.RESCHEDULE_PATIENT_FAILURE,
    payload: { error },
  }),
};
