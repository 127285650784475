/**
 * Types
 */
export const Types = {
  GET_REQUEST: '@user/GET_REQUEST',
  GET_SUCCESS: '@user/GET_SUCCESS',
  GET_FAILURE: '@user/GET_FAILURE',
  SAVE_REQUEST: '@user/SAVE_REQUEST',
  SAVE_SUCCESS: '@user/SAVE_SUCCESS',
  SAVE_FAILURE: '@user/SAVE_FAILURE',
  GET_ANAMNESE_REQUEST: '@user/GET_ANAMNESE_REQUEST',
  GET_ANAMNESE_SUCCESS: '@user/GET_ANAMNESE_SUCCESS',
  GET_ANAMNESE_FAILURE: '@user/GET_ANAMNESE_FAILURE',
  SAVE_ANAMNESIS_REQUEST: '@user/SAVE_ANAMNESIS_REQUEST',
  SAVE_ANAMNESIS_SUCCESS: '@user/SAVE_ANAMNESIS_SUCCESS',
  SAVE_ANAMNESIS_FAILURE: '@user/SAVE_ANAMNESIS_FAILURE',
  GET_PERMISSIONS_REQUEST: '@user/GET_PERMISSIONS_REQUEST',
  GET_PERMISSIONS_SUCCESS: '@user/GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_FAILURE: '@user/GET_PERMISSIONS_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  id: 0,
  object: {},
  rowbody: {},
  error: null,
  success: null,
  anamnesis: null,
  permissions: null,
};

export default function users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
    case Types.GET_ANAMNESE_REQUEST:
    case Types.GET_PERMISSIONS_REQUEST:
      return { ...state, id: action.payload.id };

    case Types.GET_FAILURE:
    case Types.SAVE_FAILURE:
    case Types.GET_ANAMNESE_FAILURE:
    case Types.SAVE_ANAMNESIS_FAILURE:
      return {
        ...state,
        success: null,
        anamnesis: null,
        error: action.payload.error,
      };

    case Types.GET_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
        object: action.payload.object,
      };

    case Types.GET_ANAMNESE_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
        anamnesis: action.payload.anamnese,
      };

    case Types.SAVE_REQUEST:
    case Types.SAVE_ANAMNESIS_REQUEST:
      return { ...state, rowbody: action.payload.rowbody, error: null };

    case Types.SAVE_SUCCESS:
    case Types.SAVE_ANAMNESIS_SUCCESS:
      return { ...state, success: action.payload.success, error: null };

    case Types.GET_PERMISSIONS_SUCCESS:
      return { ...state, permissions: action.payload.permissions, error: null };

    case Types.GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        success: null,
        anamnesis: null,
        permissions: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getUserRequest: id => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),

  getUserSuccess: object => ({
    type: Types.GET_SUCCESS,
    payload: { object },
  }),

  getUserFailure: error => ({
    type: Types.GET_FAILURE,
    payload: { error },
  }),

  saveUserRequest: rowbody => ({
    type: Types.SAVE_REQUEST,
    payload: { rowbody },
  }),

  saveUserSuccess: success => ({
    type: Types.SAVE_SUCCESS,
    payload: { success },
  }),

  saveUserFailure: error => ({
    type: Types.SAVE_FAILURE,
    payload: { error },
  }),

  getAnamneseRequest: id => ({
    type: Types.GET_ANAMNESE_REQUEST,
    payload: { id },
  }),

  getAnamneseSuccess: anamnese => ({
    type: Types.GET_ANAMNESE_SUCCESS,
    payload: { anamnese },
  }),

  getAnamneseFailure: error => ({
    type: Types.GET_ANAMNESE_FAILURE,
    payload: { error },
  }),

  saveGrupoAnamneseRequest: rowbody => ({
    type: Types.SAVE_ANAMNESIS_REQUEST,
    payload: { rowbody },
  }),

  saveGrupoAnamneseSuccess: success => ({
    type: Types.SAVE_ANAMNESIS_SUCCESS,
    payload: { success },
  }),

  saveGrupoAnamneseFailure: error => ({
    type: Types.SAVE_ANAMNESIS_FAILURE,
    payload: { error },
  }),

  getPermissionsRequest: id => ({
    type: Types.GET_PERMISSIONS_REQUEST,
    payload: { id },
  }),

  getPermissionsSuccess: permissions => ({
    type: Types.GET_PERMISSIONS_SUCCESS,
    payload: { permissions },
  }),

  getPermissionsFailure: error => ({
    type: Types.GET_PERMISSIONS_FAILURE,
    payload: { error },
  }),
};
