import { call, put } from 'redux-saga/effects';

import M from 'materialize-css';

import { Creators as RegisterActions } from '../ducks/register';

import api from '../../services/api';

export function* listGetClinic(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      api.get,
      `site-busca/get-clinica/${payload.id}`
    );
    if (data.records) {
      const { records } = data;
      yield put(RegisterActions.getClinicSuccess(records));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* saveRegister(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      api.post,
      `site-busca/inserir-paciente/`,
      payload.form
    );
    if (data.records) {
      const { records } = data;
      yield put(RegisterActions.postInsertSuccess(records));
      if (data.records.error) {
        M.toast({
          html: data.records.msg,
          displayLength: 3000,
        });
      } else {
        M.toast({
          html: 'Cadastro feito com sucesso!',
          displayLength: 3000,
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
}
