/**
 * Types
 */
export const Types = {
  LIST_REQUEST: '@payment/LIST_REQUEST',
  LIST_SUCCESS: '@payment/LIST_SUCCESS',
  LIST_FAILURE: '@payment/LIST_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  id: 0,
  data: [],
  error: null,
  loading: true,
  payment: null,
};

export default function payments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true, id: action.payload.id };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        data: action.payload.data,
      };

    case Types.LIST_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listPaymentsRequest: id => ({
    type: Types.LIST_REQUEST,
    payload: { id },
  }),

  listPaymentsSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listPaymentsFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error },
  }),
};
