/**
 * Types
 */
export const Types = {
  LIST_REQUEST: '@daily/LIST_REQUEST',
  LIST_SUCCESS: '@daily/LIST_SUCCESS',
  LIST_FAILURE: '@daily/LIST_FAILURE',
  SAVE_REQUEST: '@daily/SAVE_REQUEST',
  SAVE_SUCCESS: '@daily/SAVE_SUCCESS',
  SAVE_FAILURE: '@daily/SAVE_FAILURE',
  LIST_HISTORY_REQUEST: '@daily/LIST_HISTORY_REQUEST',
  LIST_HISTORY_SUCCESS: '@daily/LIST_HISTORY_SUCCESS',
  LIST_HISTORY_FAILURE: '@daily/LIST_HISTORY_FAILURE',
  SET_REMOVED_FEELINGS: '@daily/SET_REMOVED_FEELINGS',
  SET_REMOVED_ACTIVITIES: '@daily/SET_REMOVED_ACTIVITIES',
  GET_DAILY_ANSWER_REQUEST: '@daily/GET_DAILY_ANSWER_REQUEST',
  GET_DAILY_ANSWER_SUCCESS: '@daily/GET_DAILY_ANSWER_SUCCESS',
  GET_DAILY_ANSWER_FAILURE: '@daily/GET_DAILY_ANSWER_FAILURE',
  SAVE_ACTIVITY_REQUEST: '@activity/SAVE_ACTIVITY_REQUEST',
  SAVE_ACTIVITY_SUCCESS: '@activity/SAVE_ACTIVITY_SUCCESS',
  SAVE_ACTIVITY_FAILURE: '@activity/SAVE_ACTIVITY_FAILURE',
  LIST_ACTIVITIES_REQUEST: '@activity/LIST_ACTIVITIES_REQUEST',
  LIST_ACTIVITIES_SUCCESS: '@activity/LIST_ACTIVITIES_SUCCESS',
  LIST_ACTIVITIES_FAILURE: '@activity/LIST_ACTIVITIES_FAILURE',
  SAVE_ANSWER_REQUEST: '@activity/SAVE_ANSWER_REQUEST',
  SAVE_ANSWER_SUCCESS: '@activity/SAVE_ANSWER_SUCCESS',
  SAVE_ANSWER_FAILURE: '@activity/SAVE_ANSWER_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  id: 0,
  data: [],
  history: [],
  activities: [],
  item: {},
  activity: {},
  answers: null,
  daily: null,
  error: null,
  success: null,
  answer: null,
  removedFeelings: [],
  removedActivities: [],
};

export default function daily(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
    case Types.LIST_HISTORY_REQUEST:
    case Types.LIST_ACTIVITIES_REQUEST:
      return { ...state, error: null, success: null };

    case Types.LIST_SUCCESS:
      return { ...state, error: null, daily: action.payload.data };

    case Types.SAVE_SUCCESS:
    case Types.SAVE_ANSWER_SUCCESS:
    case Types.SAVE_ACTIVITY_SUCCESS:
      return { ...state, error: null, success: action.payload.success };

    case Types.LIST_FAILURE:
    case Types.SAVE_FAILURE:
    case Types.SAVE_ANSWER_FAILURE:
    case Types.LIST_HISTORY_FAILURE:
    case Types.SAVE_ACTIVITY_FAILURE:
    case Types.LIST_ACTIVITIES_FAILURE:
    case Types.GET_DAILY_ANSWER_FAILURE:
      return { ...state, error: action.payload.error, success: null };

    case Types.SAVE_REQUEST:
      return { ...state, error: null, item: action.payload.data };

    case Types.SAVE_ACTIVITY_REQUEST:
      return { ...state, error: null, activity: action.payload.data };

    case Types.LIST_ACTIVITIES_SUCCESS:
      return { ...state, error: null, activities: action.payload.data };

    case Types.LIST_HISTORY_SUCCESS:
      return { ...state, error: null, history: action.payload.data };

    case Types.GET_DAILY_ANSWER_REQUEST:
      return { ...state, error: null, id: action.payload.id };

    case Types.GET_DAILY_ANSWER_SUCCESS:
      return { ...state, error: null, answers: action.payload.data };

    case Types.SET_REMOVED_FEELINGS:
      return {
        ...state,
        error: null,
        removedFeelings: action.payload.removeds,
      };

    case Types.SET_REMOVED_ACTIVITIES:
      return {
        ...state,
        error: null,
        removedActivities: action.payload.removeds,
      };

    case Types.SAVE_ANSWER_REQUEST:
      return {
        ...state,
        error: null,
        answer: action.payload.answer,
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listDailyRequest: () => ({
    type: Types.LIST_REQUEST,
  }),

  listDailySuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listDailyFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error },
  }),

  saveDailyRequest: data => ({
    type: Types.SAVE_REQUEST,
    payload: { data },
  }),

  saveDailySuccess: success => ({
    type: Types.SAVE_SUCCESS,
    payload: { success },
  }),

  saveDailyFailure: error => ({
    type: Types.SAVE_FAILURE,
    payload: { error },
  }),

  listActivitiesRequest: () => ({
    type: Types.LIST_ACTIVITIES_REQUEST,
  }),

  listActivitiesSuccess: data => ({
    type: Types.LIST_ACTIVITIES_SUCCESS,
    payload: { data },
  }),

  listActivitiesFailure: error => ({
    type: Types.LIST_ACTIVITIES_FAILURE,
    payload: { error },
  }),

  saveActivityRequest: data => ({
    type: Types.SAVE_ACTIVITY_REQUEST,
    payload: { data },
  }),

  saveActivitySuccess: success => ({
    type: Types.SAVE_ACTIVITY_SUCCESS,
    payload: { success },
  }),

  saveActivityFailure: error => ({
    type: Types.SAVE_ACTIVITY_FAILURE,
    payload: { error },
  }),

  listHistoryRequest: () => ({
    type: Types.LIST_HISTORY_REQUEST,
  }),

  listHistorySuccess: data => ({
    type: Types.LIST_HISTORY_SUCCESS,
    payload: { data },
  }),

  listHistoryFailure: error => ({
    type: Types.LIST_HISTORY_FAILURE,
    payload: { error },
  }),

  getDailyAnswerRequest: id => ({
    type: Types.GET_DAILY_ANSWER_REQUEST,
    payload: { id },
  }),

  getDailyAnswerSuccess: data => ({
    type: Types.GET_DAILY_ANSWER_SUCCESS,
    payload: { data },
  }),

  getDailyAnswerFailure: error => ({
    type: Types.GET_DAILY_ANSWER_FAILURE,
    payload: { error },
  }),

  setRemovedActivities: removeds => ({
    type: Types.SET_REMOVED_ACTIVITIES,
    payload: { removeds },
  }),

  setRemovedFeelings: removeds => ({
    type: Types.SET_REMOVED_FEELINGS,
    payload: { removeds },
  }),

  saveAnswerRequest: answer => ({
    type: Types.SAVE_ANSWER_REQUEST,
    payload: { answer },
  }),

  saveAnswerSuccess: data => ({
    type: Types.SAVE_ANSWER_SUCCESS,
    payload: { data },
  }),

  saveAnswerFailure: error => ({
    type: Types.SAVE_ANSWER_FAILURE,
    payload: { error },
  }),
};
