import M from 'materialize-css';
import intl from 'react-intl-universal';

import { call, put, select } from 'redux-saga/effects';
import { Creators as DailyActions } from '../ducks/daily';

import API from '../../services/api';
import Constants from '../../factories/constants';

export function* listDaily() {
  try {
    const { data } = yield call(API.get, '/humor/diario-emocoes');
    if (data.records.error) {
      yield put(
        DailyActions.listDailyFailure(
          'Nenhum diário de emoções cadastrado até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(DailyActions.listDailySuccess(records.model));
    }
  } catch (err) {
    yield put(
      DailyActions.listDailyFailure(
        'Erro interno no módulo de diário de emoções!'
      )
    );
  }
}

export function* saveDaily(action) {
  try {
    const { payload } = action;

    let response = null;
    if (payload.data.cod_fluxo_pac) {
      response = yield call(API.put, `/humor/salvar-diario`, payload.data);
    } else {
      response = yield call(API.post, `/humor/salvar-diario`, payload.data);
    }

    const { data } = response;
    const message = data.records.msg;
    if (data.records.error) {
      yield put(DailyActions.saveDailyFailure(message));
    } else {
      yield put(DailyActions.saveDailySuccess(message));

      const url =
        payload.data.cod_modelo_fluxo === Constants.EMOTION_DIARIES.MODEL_1
          ? `/diary/history`
          : `/diary/edit/${data.records.id}`;

      setTimeout(() => {
        window.location.href = url;
      }, 2000);
    }

    M.toast({
      html: message,
      displayLength: 3000,
    });
  } catch (err) {
    yield put(
      DailyActions.saveDailyFailure(
        'Erro interno no módulo de salvar o diário de emoções!'
      )
    );
  }
}

export const getDaily = state => state.daily;

export function* saveActivity(action) {
  try {
    const { payload } = action;
    const response = yield call(
      API.post,
      `/humor/diario-emocoes/atividade`,
      payload.data
    );

    const { data } = response;
    if (data.records.error) {
      yield put(DailyActions.saveActivityFailure(data.records.msg));
    } else {
      yield put(DailyActions.saveActivitySuccess(data.records.msg));

      const daily = yield select(getDaily);
      const cloneActivities = [...daily.activities];

      cloneActivities.push(data.records.data);

      yield put(DailyActions.listActivitiesSuccess(cloneActivities));
      setTimeout(() => {
        M.toast({
          html: intl.get('ATIVIDADE_SALVA_COM_SUCESSO'),
          displayLength: 3000,
        });
      }, 1000);
    }
  } catch (err) {
    yield put(
      DailyActions.saveActivityFailure(
        'Erro interno no módulo de salvar atividades!'
      )
    );
  }
}

export function* listActivities() {
  try {
    const { data } = yield call(API.get, '/humor/diario-emocoes/atividade');
    if (data.records.error) {
      yield put(
        DailyActions.listActivitiesFailure(
          'Nenhuma atividade cadastrada até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(DailyActions.listActivitiesSuccess(records));
    }
  } catch (err) {
    yield put(
      DailyActions.listActivitiesFailure(
        'Erro interno no módulo de atividades!'
      )
    );
  }
}

export function* listHistory() {
  try {
    const { data } = yield call(API.get, '/humor/diario-emocoes/historico');
    if (data.records.error) {
      yield put(
        DailyActions.listHistoryFailure('Nenhuma histórico encontrado!')
      );
    } else {
      const { records } = data;
      yield put(DailyActions.listHistorySuccess(records));
    }
  } catch (err) {
    yield put(
      DailyActions.listHistoryFailure('Erro interno no módulo de histórico!')
    );
  }
}

export function* getDailyAnswer(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/humor/diario-emocoes/respostas/${payload.id}/?q=(pwa:1)`
    );
    if (data.records.error) {
      yield put(
        DailyActions.getDailyAnswerFailure(
          'Nenhuma resposta encontrada para o diário em questão!'
        )
      );
    } else {
      const { records } = data;
      yield put(DailyActions.getDailyAnswerSuccess(records));
    }
  } catch (err) {
    yield put(
      DailyActions.getDailyAnswerFailure('Erro interno no módulo de respostas!')
    );
  }
}

export function* saveAnswer(action) {
  try {
    const { payload } = action;

    const codFluxoPacResp = payload.answer.cod_fluxo_pac_resp
      ? payload.answer.cod_fluxo_pac_resp
      : 0;

    const response = yield call(
      API.put,
      `/humor/diario-emocoes/salvar-resposta/${codFluxoPacResp}`,
      payload.answer
    );

    const { data } = response;
    if (data.records.error) {
      yield put(DailyActions.saveAnswerFailure(data.records.msg));
    } else {
      yield put(DailyActions.saveAnswerSuccess(data.records.msg));
      setTimeout(() => {
        M.toast({
          html: intl.get('DADOS_SALVOS_COM_SUCESSO'),
          displayLength: 3000,
        });
        setTimeout(() => {
          window.location.href = `/diary/edit/${payload.answer.cod_fluxo_pac}`;
        }, 200);
      }, 200);
    }
  } catch (err) {
    yield put(
      DailyActions.saveActivityFailure(
        'Erro interno no módulo de salvar uma resposta!'
      )
    );
  }
}
