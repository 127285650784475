export const Types = {
  GET_CLINIC_REQUEST: '@clinic/GET_CLINIC_REQUEST',
  GET_CLINIC_SUCCESS: '@clinic/GET_CLINIC_SUCCESS',
  POST_INSERT_USER_REQUEST: '@clinic/POST_INSERT_USER_REQUEST',
  POST_INSERT_USER_SUCCESS: '@clinic/POST_INSERT_USER_SUCCESS',
};

const INITIAL_STATE = {
  id: null,
  msg: null,
  form: {},
  clinic: {},
};

export default function register(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CLINIC_REQUEST:
      return { ...state, id: action.payload.id };
    case Types.GET_CLINIC_SUCCESS:
      return { ...state, clinic: action.payload.clinic };
    case Types.POST_INSERT_USER_REQUEST:
      return { ...state, form: action.payload.form };
    case Types.POST_INSERT_USER_SUCCESS:
      return { ...state, msg: action.payload.msg };
    default:
      return { ...state };
  }
}

export const Creators = {
  getClinicRequest: id => ({
    type: Types.GET_CLINIC_REQUEST,
    payload: { id },
  }),

  getClinicSuccess: clinic => ({
    type: Types.GET_CLINIC_SUCCESS,
    payload: { clinic },
  }),

  postInsertRequest: form => ({
    type: Types.POST_INSERT_USER_REQUEST,
    payload: { form },
  }),

  postInsertSuccess: msg => ({
    type: Types.POST_INSERT_USER_SUCCESS,
    payload: { msg },
  }),
};
