/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
import React from 'react';
import InputMask from 'react-input-mask';

import '../Input/Input.css';

function MaskInput({
  value,
  onChange,
  Label,
  required,
  small,
  mask,
  extraSmall,
  middle,
  placeholder,
}) {
  return (
    <div
      className={`containerInput ${small && 'small'} ${extraSmall &&
        'extraSmall'} ${middle && 'middle'}`}
    >
      <div className="labelContainer">
        <p id="labelInput">{Label}</p>
        {required && <span className="required">*</span>}
      </div>
      <InputMask
        mask={mask}
        onChange={onChange}
        value={value}
        id="input"
        placeholder={placeholder}
      />
    </div>
  );
}

export default MaskInput;
