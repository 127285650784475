import { call, put } from 'redux-saga/effects';

import { Creators as PaymentActions } from '../ducks/payments';

import API from '../../services/api';

export function* listPayments(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/paciente/listar-cobranca/${payload.id}`
    );
    if (!data.records.length) {
      yield put(
        PaymentActions.listPaymentsFailure(
          'Nenhuma cobrança registrada até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(PaymentActions.listPaymentsSuccess(records));
    }
  } catch (err) {
    yield put(
      PaymentActions.listPaymentsFailure(
        'Erro interno no módulo de listagem de pagamentos!'
      )
    );
  }
}
