/**
 * Types
 */
export const Types = {
  GET_REQUEST: '@mood/GET_REQUEST',
  GET_SUCCESS: '@mood/GET_SUCCESS',
  GET_FAILURE: '@mood/GET_FAILURE',
  LIST_REQUEST: '@mood/LIST_REQUEST',
  LIST_SUCCESS: '@mood/LIST_SUCCESS',
  LIST_FAILURE: '@mood/LIST_FAILURE',
  SAVE_REQUEST: '@mood/SAVE_REQUEST',
  SAVE_SUCCESS: '@mood/SAVE_SUCCESS',
  SAVE_FAILURE: '@mood/SAVE_FAILURE',
  DELETE_REQUEST: '@mood/DELETE_REQUEST',
  DELETE_SUCCESS: '@mood/DELETE_SUCCESS',
  DELETE_FAILURE: '@mood/DELETE_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  id: 0,
  data: [],
  mood: null,
  error: null,
  object: {},
  success: null,
};

export default function moods(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, error: null, success: null };

    case Types.GET_REQUEST:
    case Types.DELETE_REQUEST:
      return { ...state, id: action.payload.id };

    case Types.SAVE_REQUEST:
      return { ...state, error: null, object: action.payload.object };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.payload.data,
      };

    case Types.GET_SUCCESS:
      return { ...state, error: null, mood: action.payload.mood };

    case Types.GET_FAILURE:
    case Types.LIST_FAILURE:
    case Types.SAVE_FAILURE:
    case Types.DELETE_FAILURE:
      return { ...state, error: action.payload.error, success: null };

    case Types.SAVE_SUCCESS:
    case Types.DELETE_SUCCESS:
      return { ...state, error: null, success: action.payload.success };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listMoodsRequest: () => ({
    type: Types.LIST_REQUEST,
  }),

  listMoodsSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listMoodsFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error },
  }),

  getMoodRequest: id => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),

  getMoodSuccess: mood => ({
    type: Types.GET_SUCCESS,
    payload: { mood },
  }),

  getMoodFailure: error => ({
    type: Types.GET_FAILURE,
    payload: { error },
  }),

  saveMoodRequest: object => ({
    type: Types.SAVE_REQUEST,
    payload: { object },
  }),

  saveMoodSuccess: success => ({
    type: Types.SAVE_SUCCESS,
    payload: { success },
  }),

  saveMoodFailure: error => ({
    type: Types.SAVE_FAILURE,
    payload: { error },
  }),

  deleteMoodRequest: id => ({
    type: Types.DELETE_REQUEST,
    payload: { id },
  }),

  deleteMoodSuccess: success => ({
    type: Types.DELETE_SUCCESS,
    payload: { success },
  }),

  deleteMoodFailure: error => ({
    type: Types.DELETE_FAILURE,
    payload: { error },
  }),
};
