import { call, put } from 'redux-saga/effects';

import { Creators as MoodActions } from '../ducks/moods';

import API from '../../services/api';

export function* listMoods() {
  try {
    const { data } = yield call(API.get, '/humor/');
    if (!data.records.length) {
      yield put(
        MoodActions.listMoodsFailure('Nenhum humor cadastrado até o momento!')
      );
    } else {
      const { records } = data;
      yield put(MoodActions.listMoodsSuccess(records));
    }
  } catch (err) {
    yield put(
      MoodActions.listMoodsFailure(
        'Erro interno no módulo de listagem de humor!'
      )
    );
  }
}

export function* getMood(action) {
  try {
    const { payload } = action;
    const { data } = yield call(API.get, `/humor/${payload.id}`);
    if (data.records) {
      const { records } = data;
      yield put(MoodActions.getMoodSuccess(records));
    } else {
      yield put(MoodActions.getMoodFailure('Humor inexistente!'));
    }
  } catch (err) {
    yield put(
      MoodActions.getMoodFailure(
        'Erro interno no módulo de recolher os dados do humor!'
      )
    );
  }
}

export function* saveMood(action) {
  try {
    const { payload } = action;

    let response = null;
    if (payload.object.cod_humor) {
      response = yield call(
        API.put,
        `/humor/${payload.object.cod_humor}`,
        payload.object
      );
    } else {
      response = yield call(API.post, '/humor/', payload.object);
    }

    const { data } = response;
    if (data.records.error) {
      yield put(MoodActions.saveMoodFailure(data.records.msg));
    } else {
      yield put(MoodActions.saveMoodSuccess(data.records.msg));
    }
  } catch (err) {
    yield put(
      MoodActions.saveMoodFailure(
        'Erro interno no módulo de salvar os dados do humor!'
      )
    );
  }
}

export function* deleteMood(action) {
  try {
    const { payload } = action;
    const { data } = yield call(API.put, `/humor/cancelar/${payload.id}`, {
      cod_humor: payload.id,
    });

    if (data.records.error) {
      yield put(MoodActions.deleteMoodFailure(data.records.msg));
    } else {
      yield put(MoodActions.deleteMoodSuccess(data.records.msg));
    }
  } catch (err) {
    yield put(
      MoodActions.deleteMoodFailure(
        'Erro interno no módulo de remover o humor!'
      )
    );
  }
}
