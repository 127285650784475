const whiteLabel = localStorage.getItem('@white_label');

let Config = {
  URL_IMAGE:
    'https://app.psicomanager.com/gc/index/get-avatar-profissional?file=',
  URL_API_PRODUCTION: 'https://apip.virtux.com.br/v1/1012/psico',
  URL_API_DEVELOPMENT: 'http://localhost/apip/v1/1012/psico',
};

if (whiteLabel === '_terapiaplay') {
  Config = {
    URL_IMAGE:
      'https://app.terapiainterativa.com.br/gc/index/get-avatar-profissional?file=',
    URL_API_PRODUCTION: 'https://api.terapiainterativa.com.br/v1/1012/psico',
    URL_API_DEVELOPMENT: 'http://localhost/apip/v1/1012/psico',
  };
}

const ConfigFinal = Config;

/** https://apip.virtux.com.br/v1/1012/psico */
/** http://localhost/apip/v1/1012/psico */

export default ConfigFinal;
