import React, { Component } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { purple, pink } from '@material-ui/core/colors';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import Store from './store';
import history from './services/history';
import Constants from './factories/constants';

import { getStorage, isAuthenticated } from './services/storage';

import Home from './components/Home';
import Offline from './components/Offline';

import Login from './pages/login';
import Register from './pages/register';
import Esqueceu from './pages/esqueceu';
import Redefinir from './pages/redefinir';
import Agendamento from './pages/psychologist';

import './styles/styles.css';
import './App.css';

const PrivateRoute = ({ componentAux: ComponentAux, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <ComponentAux {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

const locales = {
  'es-ES': require('./locales/es-ES.json'),
  'en-US': require('./locales/en-US.json'),
  'pt-BR': require('./locales/pt-BR.json'),
  'pt-PT': require('./locales/pt-PT.json'),
};

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: pink,
  },
});

export default class App extends Component {
  componentDidMount() {
    this.configLocale();
  }

  configLocale = () => {
    let currentLocale = 'pt-BR';
    const storage = getStorage();
    if (storage) {
      const { lingua } = storage;
      switch (lingua) {
        case Constants.LANGUAGE.EN:
          currentLocale = 'en-US';
          break;
        case Constants.LANGUAGE.ES:
          currentLocale = 'es-ES';
          break;
        case Constants.LANGUAGE.PT:
          currentLocale = 'pt-PT';
          break;
        default:
          currentLocale = 'pt-BR';
          break;
      }
    }

    intl.init({ currentLocale, locales });
  };

  // <React.Suspense fallback={<Loading />}></React.Suspense>
  render() {
    return (
      <div className="App">
        <Provider store={Store}>
          <BrowserRouter history={history}>
            <React.Suspense>
              <Offline text="Offline! :(" />
              <MuiThemeProvider theme={theme}>
                <Switch>
                  <Redirect exact from="/" to="/diary" />
                  <Route
                    path="/login"
                    name="Login Page"
                    render={props => <Login {...props} />}
                  />
                  <Route
                    path="/register/:id"
                    name="Register Page"
                    render={props => <Register {...props} />}
                  />
                  <Route
                    path="/esqueceu"
                    name="Esqueceu"
                    render={props => <Esqueceu {...props} />}
                  />
                  <Route
                    path="/redefinir-senha/:token"
                    name="Redefinir Senha"
                    render={props => <Redefinir {...props} />}
                  />
                  <Route
                    path="/p/:id"
                    name="Agendamento"
                    render={props => <Agendamento {...props} />}
                  />
                  <PrivateRoute
                    path="/"
                    name="Home"
                    componentAux={props => <Home {...props} />}
                  />
                </Switch>
              </MuiThemeProvider>
            </React.Suspense>
          </BrowserRouter>
        </Provider>
      </div>
    );
  }
}

PrivateRoute.propTypes = {
  componentAux: PropTypes.func,
  location: PropTypes.shape({}),
};

PrivateRoute.defaultProps = {
  componentAux: () => {},
  location: {},
};

/**
 * Terminar a refatoração das telas adicionando redux;
 * yarn add @date-io/date-fns@1.3.9
 * yarn remove sweetalert
 * yarn remove sweetalert-react
 * yarn add clsx
 */
