import { call, put } from 'redux-saga/effects';

import { Creators as ConsultationActions } from '../ducks/consultations';

import API from '../../services/api';

export function* listConsultations() {
  try {
    const { data } = yield call(API.get, '/agenda/ses-pac/');
    if (!data.records.length) {
      yield put(
        ConsultationActions.listConsultationsFailure(
          'Nenhuma consulta cadastrada até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(ConsultationActions.listConsultationsSuccess(records));
    }
  } catch (err) {
    yield put(
      ConsultationActions.listConsultationsFailure(
        'Erro interno no módulo de listagem de consultas!'
      )
    );
  }
}

export function* getConsultation(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/agenda/get-sessao-paciente/${payload.id}`
    );
    if (data.records) {
      const { records } = data;
      yield put(ConsultationActions.getConsultationSuccess(records));
    } else {
      yield put(
        ConsultationActions.getConsultationFailure('Consulta inexistente!')
      );
    }
  } catch (err) {
    yield put(
      ConsultationActions.getConsultationFailure(
        'Erro interno no módulo de recolher os dados da consulta!'
      )
    );
  }
}
