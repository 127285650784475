import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from '../Header';
import Footer from '../Footer';
import routes from '../../routes';
import Progress from '../Progress';

import API from '../../services/api';

import { logout, getStorage } from '../../services/storage';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      diary: true,
      whiteLabel: '_psicomanager',
    };
  }

  async componentDidMount() {
    const whiteLabel = localStorage.getItem('@white_label');
    const response = await API.get('/humor/diario-emocoes');
    const flag = response.data.records.error;
    setTimeout(() => {
      this.setState({ render: true, diary: flag, whiteLabel });
    }, 100);
  }

  getPathName() {
    const { location } = this.props;
    const { whiteLabel } = this.state;
    const pathname = `/${location.pathname.split('/')[1]}`;
    const route = routes.filter(routeItem => {
      if (routeItem.path === pathname) {
        return routeItem;
      }
      return null;
    });
    let s =
      whiteLabel === '_psicomanager' ? 'PsicoManager' : 'Terapia Interativa';
    if (route) {
      if (route.length) {
        s = route[0].name;
      }
    }
    return s;
  }

  render() {
    const storage = getStorage();
    const { render, diary, whiteLabel } = this.state;
    return (
      render && (
        <div>
          <Header
            logout={logout}
            diary={diary}
            whiteLabel={whiteLabel}
            rota={this.getPathName()}
            storage={storage}
          />
          <main>
            <Suspense fallback={<Progress />}>
              <Switch>
                {routes.map(route =>
                  route.component ? (
                    <Route
                      key={Math.random()}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  ) : null
                )}
              </Switch>
            </Suspense>
          </main>
          <Footer diary={diary} />
        </div>
      )
    );
  }
}

Home.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        split: PropTypes.func,
      }),
    ]),
  }).isRequired,
};

export default Home;
