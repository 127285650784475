/**
 * Types
 */
export const Types = {
  GET_REQUEST: '@consultation/GET_REQUEST',
  GET_SUCCESS: '@consultation/GET_SUCCESS',
  GET_FAILURE: '@consultation/GET_FAILURE',
  LIST_REQUEST: '@consultation/LIST_REQUEST',
  LIST_SUCCESS: '@consultation/LIST_SUCCESS',
  LIST_FAILURE: '@consultation/LIST_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  id: 0,
  data: [],
  error: null,
  loading: true,
  consultation: null,
};

export default function consultations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, loading: true };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        data: action.payload.data,
      };

    case Types.GET_FAILURE:
    case Types.LIST_FAILURE:
      return { ...state, error: action.payload.error, loading: false };

    case Types.GET_REQUEST:
      return { ...state, id: action.payload.id, loading: true };

    case Types.GET_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        consultation: action.payload.consultation,
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listConsultationsRequest: () => ({
    type: Types.LIST_REQUEST,
  }),

  listConsultationsSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listConsultationsFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error },
  }),

  getConsultationRequest: id => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),

  getConsultationSuccess: consultation => ({
    type: Types.GET_SUCCESS,
    payload: { consultation },
  }),

  getConsultationFailure: error => ({
    type: Types.GET_FAILURE,
    payload: { error },
  }),
};
