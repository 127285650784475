/* eslint-disable react/destructuring-assignment */

import React from 'react';
import PropTypes from 'prop-types';

import Factories from '../../factories';

function GetItem(props) {
  const { redirect, alias, onclick, icon, divider } = props;
  return (
    <div className="item-menu">
      <li>
        <a href={redirect} title={alias} onClick={onclick}>
          <i className="material-icons">{icon}</i> {alias}
        </a>
      </li>
      {divider ? <div className="divider" /> : null}
    </div>
  );
}

const ItemMenu = props => (
  <div>
    {Factories.getVerificaPermissao(props.permission) ? (
      <GetItem {...props} />
    ) : null}
  </div>
);

ItemMenu.propTypes = {
  permission: PropTypes.number,
};

ItemMenu.defaultProps = {
  permission: 2,
};

GetItem.propTypes = {
  redirect: PropTypes.string,
  alias: PropTypes.string,
  onclick: PropTypes.func,
  icon: PropTypes.string,
  divider: PropTypes.bool,
};

GetItem.defaultProps = {
  redirect: '/',
  alias: 'PsicoManager',
  onclick: () => {},
  icon: '',
  divider: true,
};

export default ItemMenu;
