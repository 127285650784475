/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import M from 'materialize-css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loading from '../../components/Loading';
import Config from '../../config';

import './styles.css';

class Redefinir extends Component {
  constructor(props) {
    super(props);
    this.state = {
      h: '',
      novaSenha: '',
      repeatSenha: '',
      loading: true,
      whiteLabel: '_psicomanager',
    };
  }

  componentDidMount() {
    const whiteLabel = localStorage.getItem('@white_label');
    this.setState({
      h: this.props.match.params.token,
      loading: false,
      whiteLabel,
    });
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    const { novaSenha, repeatSenha, h } = this.state;

    if (!novaSenha || !repeatSenha) {
      M.toast({
        html: 'Preencha a senha para continuar!',
        displayLength: 5000,
      });
      this.setState({ loading: false });
    } else if (novaSenha !== repeatSenha) {
      M.toast({
        html: 'Senha e confirmação de senha não podem ser diferentes.',
        displayLength: 5000,
      });
      this.setState({ loading: false });
    } else {
      let url =
        process.env.NODE_ENV === 'development'
          ? Config.URL_API_DEVELOPMENT
          : Config.URL_API_PRODUCTION;

      url = url.replace('/psico', '/sistema');
      const response = await fetch(
        `${url}/rotinas-gerais/redefinir-senha-pac`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nova_senha: novaSenha,
            h,
          }),
        }
      );

      // .then(response => response.json())
      // .then(response => {
      //   console.log(response);
      // });

      const content = await response.json();
      if (!content.records.error) {
        this.setState({ loading: false });
        M.toast({
          html: content.records.msg,
          displayLength: 5000,
        });
        const { history } = this.props;
        history.push('/login');
      } else {
        this.setState({ loading: false });
        M.toast({
          html: content.records.msg,
          displayLength: 5000,
        });
      }
    }
  };

  onChangePassword = async e => {
    this.setState({ novaSenha: e.target.value });
  };

  onChangeRepeat = async e => {
    this.setState({ repeatSenha: e.target.value });
  };

  render() {
    const { loading, whiteLabel } = this.state;
    return loading ? (
      <Loading />
    ) : (
      <div id="login">
        <form onSubmit={this.handleSubmit}>
          {whiteLabel === '_psicomanager' ? (
            <>
              <img
                alt="PsicoManager Paciente"
                src={require('../../assets/img/icon.png')}
              />
              <h5>PsicoManager Paciente</h5>
              <span>Aproximando o profissional do paciente</span>
            </>
          ) : (
            <>
              <img
                alt="Terapia Interativa"
                src={require('../../assets/img/icon_terapiainterativa.png')}
                className="terapia-play"
              />
              <h5>Terapia Interativa</h5>
              <span>
                Plataforma que possibilita o uso de recursos terapêuticos em
                sessões de terapia online
              </span>
            </>
          )}

          <div id="esqueceu">
            <div className="input-field col s6">
              <label htmlFor="password">Nova senha</label>
              <input
                id="password"
                aria-label="Nova senha"
                type="password"
                className="validate"
                onChange={this.onChangePassword}
              />
            </div>
            <div className="input-field col s6">
              <label htmlFor="repeat">Repetir nova senha</label>
              <input
                id="repeat"
                aria-label="Repetir nova senha"
                type="password"
                className="validate"
                onChange={this.onChangeRepeat}
              />
            </div>
            <button
              className="btn-large waves-light light-blue darken-3"
              type="submit"
            >
              <span>ATUALIZAR SENHA</span>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

Redefinir.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

Redefinir.defaultProps = {
  history: PropTypes.shape({
    push: () => {},
  }),
};

export default Redefinir;
