const Constants = {
  STEPS: {
    EMOJI: '1',
    FORMS: '2',
    FEELINGS: '4',
    ACTIVITIES: '3',
    EMOJI_FORMS: '5',
  },
  EMOJIS: {
    VERY_HAPPY: 1,
    HAPPY: 2,
    NORMAL: 3,
    SAD: 4,
    VERY_SAD: 5,
    FEAR: 6,
    DISGUSTED: 7,
    RAGE: 8,
  },
  EMOTION_DIARIES: {
    MODEL_1: 1,
    MODEL_2: 2,
    MODEL_3: 3,
    MODEL_4: 4,
  },
  LANGUAGE: {
    BR: 1,
    PT: 2,
    EN: 3,
    FR: 4,
    ES: 5,
  },
  COUNTRY: {
    BR: '1',
    PT: '2',
    AR: '3',
    CL: '4',
    CO: '5',
    MX: '6',
    PE: '7',
    UR: '8',
    EC: '9',
  },
  FIELDS: {
    TYPE_INPUT: 1,
    TYPE_TEXTAREA: 2,
    TYPE_CHECKBOX: 4,
    TYPE_SIMPLE_SELECT: 3,
    TYPE_COMPLEX_SELECT: 5,
  },
  SCHOOLING: [
    {
      id: 1,
      name: 'Fundamental Incompleto',
    },
    {
      id: 2,
      name: 'Fundamental Completo',
    },
    {
      id: 3,
      name: 'Médio Incompleto',
    },
    {
      id: 4,
      name: 'Médio Completo',
    },
    {
      id: 5,
      name: 'Superior Incompleto',
    },
    {
      id: 6,
      name: 'Superior Completo',
    },
    {
      id: 10,
      name: 'Pós Graduação',
    },
    {
      id: 7,
      name: 'Mestre',
    },
    {
      id: 8,
      name: 'Doutor',
    },
    {
      id: 9,
      name: 'Pós Doutor',
    },
    {
      id: 11,
      name: 'Infantil ou Jardim',
    },
  ],
  MEDICAL_COVENANT: {
    OK: 1,
    NO: 2,
  },
  STATES: [
    {
      id: 1,
      name: 'Acre',
      initials: 'AC',
    },
    {
      id: 2,
      name: 'Alagoas',
      initials: 'AL',
    },
    {
      id: 3,
      name: 'Amazonas',
      initials: 'AM',
    },
    {
      id: 4,
      name: 'Amapá',
      initials: 'AP',
    },
    {
      id: 5,
      name: 'Bahia',
      initials: 'BA',
    },
    {
      id: 6,
      name: 'Ceará',
      initials: 'CE',
    },
    {
      id: 7,
      name: 'Distrito Federal',
      initials: 'DF',
    },
    {
      id: 8,
      name: 'Espírito Santo',
      initials: 'ES',
    },
    {
      id: 9,
      name: 'Goiás',
      initials: 'GO',
    },
    {
      id: 10,
      name: 'Maranhão',
      initials: 'MA',
    },
    {
      id: 13,
      name: 'Mato Grosso',
      initials: 'MT',
    },
    {
      id: 12,
      name: 'Mato Grosso do Sul',
      initials: 'MS',
    },
    {
      id: 11,
      name: 'Minas Gerais',
      initials: 'MG',
    },
    {
      id: 14,
      name: 'Pará',
      initials: 'PA',
    },
    {
      id: 15,
      name: 'Paraíba',
      initials: 'PB',
    },
    {
      id: 18,
      name: 'Paraná',
      initials: 'PR',
    },
    {
      id: 16,
      name: 'Pernambuco',
      initials: 'PE',
    },
    {
      id: 17,
      name: 'Piauí',
      initials: 'PI',
    },
    {
      id: 19,
      name: 'Rio de Janeiro',
      initials: 'RJ',
    },
    {
      id: 20,
      name: 'Rio Grande do Norte',
      initials: 'RN',
    },
    {
      id: 23,
      name: 'Rio Grande do Sul',
      initials: 'RS',
    },
    {
      id: 21,
      name: 'Rondônia',
      initials: 'RO',
    },
    {
      id: 22,
      name: 'Roraima',
      initials: 'RR',
    },
    {
      id: 24,
      name: 'Santa Catarina',
      initials: 'SC',
    },
    {
      id: 26,
      name: 'São Paulo',
      initials: 'SP',
    },
    {
      id: 25,
      name: 'Sergipe',
      initials: 'SE',
    },
    {
      id: 27,
      name: 'Tocantins',
      initials: 'TO',
    },
  ],
};

export default Constants;
