import intl from 'react-intl-universal';

import Constants from './constants';

import { getStorage } from '../services/storage';

export default {
  /**
   * Índices existentes dos módulos e permissões conforme passado pelo José Guilherme:
   * Exemplo: 1|0|1|1|1|12|1|1|0
   * 0  => 'envia_link_atend_online',
   * 1  => 'envia_link_min_antes_sessao',
   * 2  => 'habilitar_app_pac',
   * 3  => 'habilitar_humor_pac',
   * 4  => 'habilitar_reagendamento_pac',
   * 5  => 'horas_antes_permit_reagendar',
   * 6  => 'exibir_status_pgto',
   * 7  => 'habilitar_cobranca',
   * 8  => 'habilitar_auto_confirma_reag',
   * 9  => 'habilitar_agendamento_pac',
   * 10 => 'habilitar_anamnese',
   * 11 => 'exibir_status_presenca'
   */
  getPermissoes() {
    const storage = getStorage();
    if (storage) {
      const permissoes = storage.notifica;
      if (permissoes) {
        return permissoes.split('|');
      }
    }
    return null;
  },

  getVerificaPermissao(indice) {
    if (indice >= 0) {
      const arrPemissoes = this.getPermissoes();
      if (arrPemissoes) {
        const permissao = arrPemissoes[indice]
          ? parseInt(arrPemissoes[indice], 10)
          : 0;
        return permissao;
      }
    }
    return false;
  },

  isEmptyObject(object) {
    if (Object.keys(object).length === 0 && object.constructor === Object) {
      return true;
    }
    return false;
  },

  importMomentLocale() {
    const storage = getStorage();
    if (storage) {
      const { lingua } = storage;
      switch (lingua) {
        case Constants.LANGUAGE.PT:
          require('moment/locale/en-au');
          break;
        case Constants.LANGUAGE.ES:
          require('moment/locale/es');
          break;
        default:
          require('moment/locale/pt-br');
          break;
      }
    }
  },

  formatDateViewToBD(date) {
    if (date) {
      const arrDate = date.split('/');
      return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
    }
    return null;
  },

  importDateFnsLocale() {
    const storage = getStorage();
    if (storage) {
      const { lingua } = storage;
      switch (lingua) {
        case Constants.LANGUAGE.EN:
          return require('date-fns/locale/en-US');
        case Constants.LANGUAGE.ES:
          return require('date-fns/locale/es');
        default:
          return require('date-fns/locale/pt-BR');
      }
    }
    return require('date-fns/locale/pt-BR');
  },

  getDateFromString(date) {
    if (date) {
      const arrDateTime = date.trim().split(' ');
      if (arrDateTime.length > 0) {
        const arrDate = arrDateTime[0].split('-');
        const arrHour = arrDateTime[1].split(':');
        if (arrDate.length > 0 && arrHour.length > 0) {
          const result = new Date(
            parseInt(arrDate[0], 10),
            parseInt(arrDate[1], 10) - 1,
            parseInt(arrDate[2], 10),
            parseInt(arrHour[0], 10),
            parseInt(arrHour[1], 10),
            parseInt(arrHour[2], 10)
          );
          return result;
        }
      }
    }
    return null;
  },

  getSimpleDateObjectFromString(date) {
    if (date) {
      const arrDate = date.split('-');
      return new Date(
        parseInt(arrDate[0], 10),
        parseInt(arrDate[1], 10) - 1,
        parseInt(arrDate[2], 10)
      );
    }
    return null;
  },

  getDateFromObject(date) {
    if (date) {
      const day = date.getDate().toString();
      const month = (date.getMonth() + 1).toString();
      const year = date.getFullYear();

      const formatDay = day.length === 1 ? `0${day}` : day;
      const formatMonth = month.length === 1 ? `0${month}` : month;

      return `${year}-${formatMonth}-${formatDay}`;
    }
    return null;
  },

  buildQueryString(objeto, strComplemento) {
    const arrFiltro = [];
    let s = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const k in objeto) {
      if (objeto[k] || objeto[k] === 0) {
        if (typeof objeto[k] === 'object') {
          if (objeto[k].length) {
            arrFiltro.push(`${k}:${objeto[k].join('')}`);
          }
        } else {
          arrFiltro.push(`${k}:${objeto[k]}`);
        }
      }
    }

    if (arrFiltro.length) {
      s = `?q=(${arrFiltro.join(',')})`;
    }

    if (strComplemento !== '' && strComplemento !== undefined) {
      s = s === '' ? strComplemento : `${s}&${strComplemento}`;
    }

    return s;
  },

  getEmojiLabel(emoji) {
    switch (emoji) {
      case Constants.EMOJIS.VERY_HAPPY:
        return intl.get('MUITO_FELIZ');
      case Constants.EMOJIS.HAPPY:
        return intl.get('FELIZ');
      case Constants.EMOJIS.NORMAL:
        return intl.get('NORMAL');
      case Constants.EMOJIS.SAD:
        return intl.get('TRISTE');
      case Constants.EMOJIS.VERY_SAD:
        return intl.get('MUITO_TRISTE');
      case Constants.EMOJIS.FEAR:
        return intl.get('MEDO');
      case Constants.EMOJIS.DISGUSTED:
        return intl.get('NOJO');
      case Constants.EMOJIS.RAGE:
        return intl.get('RAIVA');
      default:
        return null;
    }
  },

  getEmojiLabelColor(emoji) {
    switch (emoji) {
      case Constants.EMOJIS.VERY_HAPPY:
        return '#f59b25';
      case Constants.EMOJIS.HAPPY:
        return '#e2a611';
      case Constants.EMOJIS.NORMAL:
        return '#7f7f80';
      case Constants.EMOJIS.SAD:
        return '#497dbd';
      case Constants.EMOJIS.VERY_SAD:
        return '#17467c';
      case Constants.EMOJIS.FEAR:
        return '#8f569e';
      case Constants.EMOJIS.DISGUSTED:
        return '#44ac33';
      case Constants.EMOJIS.RAGE:
        return '#e62429';
      default:
        return '#000000';
    }
  },

  getFeelingsEmoji(emoji) {
    switch (emoji) {
      case Constants.EMOJIS.HAPPY:
      case Constants.EMOJIS.VERY_HAPPY:
        return [
          {
            key: 1,
            name: intl.get('ANSIOSO'),
            checked: false,
          },
          {
            key: 2,
            name: intl.get('ALEGRE'),
            checked: false,
          },
          {
            key: 3,
            name: intl.get('AMOROSO'),
            checked: false,
          },
          {
            key: 4,
            name: intl.get('OTIMISTA'),
            checked: false,
          },
          {
            key: 5,
            name: intl.get('LEVE'),
            checked: false,
          },
          {
            key: 6,
            name: intl.get('ESPECIAL'),
            checked: false,
          },
          {
            key: 7,
            name: intl.get('ORGULHOSO'),
            checked: false,
          },
          {
            key: 8,
            name: intl.get('EXCITADO'),
            checked: false,
          },
          {
            key: 9,
            name: intl.get('CONSTRANGIDO'),
            checked: false,
          },
          {
            key: 10,
            name: intl.get('BONDOSO'),
            checked: false,
          },
          {
            key: 11,
            name: intl.get('COMPETITIVO'),
            checked: false,
          },
          {
            key: 12,
            name: intl.get('EMPOLGADO'),
            checked: false,
          },
          {
            key: 13,
            name: intl.get('CONFIANTE'),
            checked: false,
          },
          {
            key: 14,
            name: intl.get('TRANQUILO'),
            checked: false,
          },
          {
            key: 15,
            name: intl.get('SAUDADE'),
            checked: false,
          },
          {
            key: 16,
            name: intl.get('VITORIA'),
            checked: false,
          },
        ];

      case Constants.EMOJIS.NORMAL:
        return [
          {
            key: 17,
            name: intl.get('MEDO'),
            checked: false,
          },
          {
            key: 1,
            name: intl.get('ANSIOSO'),
            checked: false,
          },
          {
            key: 14,
            name: intl.get('TRANQUILO'),
            checked: false,
          },
          {
            key: 18,
            name: intl.get('INDIFERENTE'),
            checked: false,
          },
          {
            key: 9,
            name: intl.get('CONSTRANGIDO'),
            checked: false,
          },
          {
            key: 19,
            name: intl.get('INSEGURO'),
            checked: false,
          },
          {
            key: 20,
            name: intl.get('ABORRECIDO'),
            checked: false,
          },
          {
            key: 21,
            name: intl.get('ASSUSTADO'),
            checked: false,
          },
          {
            key: 22,
            name: intl.get('FRUSTRADO'),
            checked: false,
          },
          {
            key: 15,
            name: intl.get('SAUDADE'),
            checked: false,
          },
        ];

      case Constants.EMOJIS.SAD:
      case Constants.EMOJIS.VERY_SAD:
        return [
          {
            key: 23,
            name: intl.get('ABALADO'),
            checked: false,
          },
          {
            key: 24,
            name: intl.get('ANGUSTIADO'),
            checked: false,
          },
          {
            key: 25,
            name: intl.get('ARREPENDIDO'),
            checked: false,
          },
          {
            key: 1,
            name: intl.get('ANSIOSO'),
            checked: false,
          },
          {
            key: 26,
            name: intl.get('CARENTE'),
            checked: false,
          },
          {
            key: 27,
            name: intl.get('DEPRIMIDO'),
            checked: false,
          },
          {
            key: 28,
            name: intl.get('RAIVA'),
            checked: false,
          },
          {
            key: 29,
            name: intl.get('DERROTADO'),
            checked: false,
          },
          {
            key: 30,
            name: intl.get('DESESPERANCA'),
            checked: false,
          },
          {
            key: 31,
            name: intl.get('FRACASSO'),
            checked: false,
          },
          {
            key: 32,
            name: intl.get('HUMILHADO'),
            checked: false,
          },
          {
            key: 33,
            name: intl.get('CULPADO'),
            checked: false,
          },
          {
            key: 34,
            name: intl.get('MAGOADO'),
            checked: false,
          },
          {
            key: 35,
            name: intl.get('IRRITADO'),
            checked: false,
          },
          {
            key: 9,
            name: intl.get('CONSTRANGIDO'),
            checked: false,
          },
          {
            key: 19,
            name: intl.get('INSEGURO'),
            checked: false,
          },
          {
            key: 17,
            name: intl.get('MEDO'),
            checked: false,
          },
          {
            key: 36,
            name: intl.get('INJUSTICADO'),
            checked: false,
          },
          {
            key: 37,
            name: intl.get('TEDIO'),
            checked: false,
          },
        ];

      case Constants.EMOJIS.FEAR:
        return [
          {
            key: 23,
            name: intl.get('ABALADO'),
            checked: false,
          },
          {
            key: 38,
            name: intl.get('AFLITO'),
            checked: false,
          },
          {
            key: 39,
            name: intl.get('AGITADO'),
            checked: false,
          },
          {
            key: 40,
            name: intl.get('AGONIA'),
            checked: false,
          },
          {
            key: 1,
            name: intl.get('ANSIOSO'),
            checked: false,
          },
          {
            key: 41,
            name: intl.get('ENCIUMADO'),
            checked: false,
          },
          {
            key: 42,
            name: intl.get('DEPENDENTE'),
            checked: false,
          },
          {
            key: 43,
            name: intl.get('DESESPERADO'),
            checked: false,
          },
          {
            key: 44,
            name: intl.get('INQUIETO'),
            checked: false,
          },
          {
            key: 19,
            name: intl.get('INSEGURO'),
            checked: false,
          },
          {
            key: 45,
            name: intl.get('MELANCOLICO'),
            checked: false,
          },
          {
            key: 46,
            name: intl.get('PRECONCEITO'),
            checked: false,
          },
          {
            key: 47,
            name: intl.get('PREOCUPADO'),
            checked: false,
          },
          {
            key: 48,
            name: intl.get('TIMIDO'),
            checked: false,
          },
          {
            key: 49,
            name: intl.get('VERGONHA'),
            checked: false,
          },
          {
            key: 50,
            name: intl.get('VULNERAVEL'),
            checked: false,
          },
        ];

      case Constants.EMOJIS.DISGUSTED:
        return [
          {
            key: 51,
            name: intl.get('ANTIPATICO'),
            checked: false,
          },
          {
            key: 52,
            name: intl.get('ARROGANTE'),
            checked: false,
          },
          {
            key: 53,
            name: intl.get('DESGASTANTE'),
            checked: false,
          },
          {
            key: 54,
            name: intl.get('DESPREZO'),
            checked: false,
          },
          {
            key: 55,
            name: intl.get('EGOISTA'),
            checked: false,
          },
          {
            key: 18,
            name: intl.get('INDIFERENTE'),
            checked: false,
          },
          {
            key: 56,
            name: intl.get('INVEJA'),
            checked: false,
          },
          {
            key: 57,
            name: intl.get('INTOLERANTE'),
            checked: false,
          },
          {
            key: 46,
            name: intl.get('PRECONCEITO'),
            checked: false,
          },
          {
            key: 58,
            name: intl.get('SARCASTICO'),
            checked: false,
          },
          {
            key: 37,
            name: intl.get('TEDIO'),
            checked: false,
          },
          {
            key: 9,
            name: intl.get('CONSTRANGIDO'),
            checked: false,
          },
        ];

      case Constants.EMOJIS.RAGE:
        return [
          {
            key: 39,
            name: intl.get('AGITADO'),
            checked: false,
          },
          {
            key: 59,
            name: intl.get('AGRESSIVO'),
            checked: false,
          },
          {
            key: 1,
            name: intl.get('ANSIOSO'),
            checked: false,
          },
          {
            key: 60,
            name: intl.get('AUTORITARIO'),
            checked: false,
          },
          {
            key: 61,
            name: intl.get('MALHUMORADO'),
            checked: false,
          },
          {
            key: 41,
            name: intl.get('ENCIUMADO'),
            checked: false,
          },
          {
            key: 62,
            name: intl.get('ESTRESSADO'),
            checked: false,
          },
          {
            key: 63,
            name: intl.get('HOSTILIDADE'),
            checked: false,
          },
          {
            key: 35,
            name: intl.get('IRRITADO'),
            checked: false,
          },
          {
            key: 64,
            name: intl.get('MALDOSO'),
            checked: false,
          },
          {
            key: 7,
            name: intl.get('ORGULHOSO'),
            checked: false,
          },
          {
            key: 65,
            name: intl.get('RANCOROSO'),
            checked: false,
          },
          {
            key: 66,
            name: intl.get('REVANCHE'),
            checked: false,
          },
          {
            key: 67,
            name: intl.get('TEIMOSO'),
            checked: false,
          },
          {
            key: 9,
            name: intl.get('CONSTRANGIDO'),
            checked: false,
          },
        ];

      default:
        return [
          {
            key: 1,
            name: intl.get('ANSIOSO'),
            checked: false,
          },
          {
            key: 2,
            name: intl.get('ALEGRE'),
            checked: false,
          },
          {
            key: 3,
            name: intl.get('AMOROSO'),
            checked: false,
          },
          {
            key: 4,
            name: intl.get('OTIMISTA'),
            checked: false,
          },
          {
            key: 5,
            name: intl.get('LEVE'),
            checked: false,
          },
          {
            key: 6,
            name: intl.get('ESPECIAL'),
            checked: false,
          },
          {
            key: 7,
            name: intl.get('ORGULHOSO'),
            checked: false,
          },
          {
            key: 8,
            name: intl.get('EXCITADO'),
            checked: false,
          },
          {
            key: 9,
            name: intl.get('CONSTRANGIDO'),
            checked: false,
          },
          {
            key: 10,
            name: intl.get('BONDOSO'),
            checked: false,
          },
          {
            key: 11,
            name: intl.get('COMPETITIVO'),
            checked: false,
          },
          {
            key: 12,
            name: intl.get('EMPOLGADO'),
            checked: false,
          },
          {
            key: 13,
            name: intl.get('CONFIANTE'),
            checked: false,
          },
          {
            key: 14,
            name: intl.get('TRANQUILO'),
            checked: false,
          },
          {
            key: 15,
            name: intl.get('SAUDADE'),
            checked: false,
          },
          {
            key: 16,
            name: intl.get('VITORIA'),
            checked: false,
          },
          {
            key: 17,
            name: intl.get('MEDO'),
            checked: false,
          },
          {
            key: 18,
            name: intl.get('INDIFERENTE'),
            checked: false,
          },
          {
            key: 19,
            name: intl.get('INSEGURO'),
            checked: false,
          },
          {
            key: 20,
            name: intl.get('ABORRECIDO'),
            checked: false,
          },
          {
            key: 21,
            name: intl.get('ASSUSTADO'),
            checked: false,
          },
          {
            key: 22,
            name: intl.get('FRUSTRADO'),
            checked: false,
          },
          {
            key: 23,
            name: intl.get('ABALADO'),
            checked: false,
          },
          {
            key: 24,
            name: intl.get('ANGUSTIADO'),
            checked: false,
          },
          {
            key: 25,
            name: intl.get('ARREPENDIDO'),
            checked: false,
          },
          {
            key: 26,
            name: intl.get('CARENTE'),
            checked: false,
          },
          {
            key: 27,
            name: intl.get('DEPRIMIDO'),
            checked: false,
          },
          {
            key: 28,
            name: intl.get('RAIVA'),
            checked: false,
          },
          {
            key: 29,
            name: intl.get('DERROTADO'),
            checked: false,
          },
          {
            key: 30,
            name: intl.get('DESESPERANCA'),
            checked: false,
          },
          {
            key: 31,
            name: intl.get('FRACASSO'),
            checked: false,
          },
          {
            key: 32,
            name: intl.get('HUMILHADO'),
            checked: false,
          },
          {
            key: 33,
            name: intl.get('CULPADO'),
            checked: false,
          },
          {
            key: 34,
            name: intl.get('MAGOADO'),
            checked: false,
          },
          {
            key: 35,
            name: intl.get('IRRITADO'),
            checked: false,
          },
          {
            key: 36,
            name: intl.get('INJUSTICADO'),
            checked: false,
          },
          {
            key: 37,
            name: intl.get('TEDIO'),
            checked: false,
          },
          {
            key: 38,
            name: intl.get('AFLITO'),
            checked: false,
          },
          {
            key: 39,
            name: intl.get('AGITADO'),
            checked: false,
          },
          {
            key: 40,
            name: intl.get('AGONIA'),
            checked: false,
          },
          {
            key: 41,
            name: intl.get('ENCIUMADO'),
            checked: false,
          },
          {
            key: 42,
            name: intl.get('DEPENDENTE'),
            checked: false,
          },
          {
            key: 43,
            name: intl.get('DESESPERADO'),
            checked: false,
          },
          {
            key: 44,
            name: intl.get('INQUIETO'),
            checked: false,
          },
          {
            key: 45,
            name: intl.get('MELANCOLICO'),
            checked: false,
          },
          {
            key: 46,
            name: intl.get('PRECONCEITO'),
            checked: false,
          },
          {
            key: 47,
            name: intl.get('PREOCUPADO'),
            checked: false,
          },
          {
            key: 48,
            name: intl.get('TIMIDO'),
            checked: false,
          },
          {
            key: 49,
            name: intl.get('VERGONHA'),
            checked: false,
          },
          {
            key: 50,
            name: intl.get('VULNERAVEL'),
            checked: false,
          },
          {
            key: 51,
            name: intl.get('ANTIPATICO'),
            checked: false,
          },
          {
            key: 52,
            name: intl.get('ARROGANTE'),
            checked: false,
          },
          {
            key: 53,
            name: intl.get('DESGASTANTE'),
            checked: false,
          },
          {
            key: 54,
            name: intl.get('DESPREZO'),
            checked: false,
          },
          {
            key: 55,
            name: intl.get('EGOISTA'),
            checked: false,
          },
          {
            key: 56,
            name: intl.get('INVEJA'),
            checked: false,
          },
          {
            key: 57,
            name: intl.get('INTOLERANTE'),
            checked: false,
          },
          {
            key: 58,
            name: intl.get('SARCASTICO'),
            checked: false,
          },
          {
            key: 59,
            name: intl.get('AGRESSIVO'),
            checked: false,
          },
          {
            key: 60,
            name: intl.get('AUTORITARIO'),
            checked: false,
          },
          {
            key: 61,
            name: intl.get('MALHUMORADO'),
            checked: false,
          },
          {
            key: 62,
            name: intl.get('ESTRESSADO'),
            checked: false,
          },
          {
            key: 63,
            name: intl.get('HOSTILIDADE'),
            checked: false,
          },
          {
            key: 64,
            name: intl.get('MALDOSO'),
            checked: false,
          },
          {
            key: 65,
            name: intl.get('RANCOROSO'),
            checked: false,
          },
          {
            key: 66,
            name: intl.get('REVANCHE'),
            checked: false,
          },
          {
            key: 67,
            name: intl.get('TEIMOSO'),
            checked: false,
          },
        ];
    }
  },

  getActivities() {
    return [
      {
        key: '1',
        name: intl.get('FAMILIA'),
        icon: 'wc',
        removable: 0,
      },
      {
        key: '2',
        name: intl.get('TRABALHO'),
        icon: 'work',
        removable: 0,
      },
      {
        key: '3',
        name: intl.get('AMIGOS'),
        icon: 'group_add',
        removable: 0,
      },
      {
        key: '4',
        name: intl.get('NAMORO'),
        icon: 'favorite',
        removable: 0,
      },
      {
        key: '5',
        name: intl.get('ATIVIDADE_FISICA'),
        icon: 'directions_bike',
        removable: 0,
      },
      {
        key: '6',
        name: intl.get('ESPORTE'),
        icon: 'sports_tennis',
        removable: 0,
      },
      {
        key: '7',
        name: intl.get('DORMIR_CEDO'),
        icon: 'hotel',
        removable: 0,
      },
      {
        key: '8',
        name: intl.get('ALIMENTACAO'),
        icon: 'fastfood',
        removable: 0,
      },
      {
        key: '9',
        name: intl.get('DESCANSO'),
        icon: 'single_bed',
        removable: 0,
      },
      {
        key: '10',
        name: intl.get('FILMES'),
        icon: 'local_movies',
        removable: 0,
      },
      {
        key: '11',
        name: intl.get('LEITURA'),
        icon: 'menu_book',
        removable: 0,
      },
      {
        key: '12',
        name: intl.get('JOGOS'),
        icon: 'videogame_asset',
        removable: 0,
      },
      {
        key: '13',
        name: intl.get('FAXINA'),
        icon: 'delete_sweep',
        removable: 0,
      },
      {
        key: '14',
        name: intl.get('COMPRAS'),
        icon: 'shopping_basket',
        removable: 0,
      },
    ];
  },

  getIcons() {
    return [
      'access_alarm',
      'accessibility',
      'accessible_forward',
      'account_balance',
      'airline_seat_individual_suite',
      'airplanemode_active',
      'assignment',
      'apartment',
      'apps',
      'attach_money',
      'audiotrack',
      'bathtub',
      'beach_access',
      'block',
      'book',
      'brush',
      'build',
      'business',
      'business_center',
      'cake',
      'call',
      'camera_alt',
      'card_giftcard',
      'child_friendly',
      'color_lens',
      'contact_support',
      'date_range',
      'desktop_mac',
      'directions_boat',
      'directions_bus',
      'directions_car',
      'directions_run',
      'emoji_events',
      'emoji_objects',
      'fitness_center',
      'hd',
      'highlight',
      'home',
      'keyboard_voice',
      'language',
      'laptop_mac',
      'live_tv',
      'local_bar',
      'local_dining',
      'local_florist',
      'local_grocery_store',
      'local_pharmacy',
      'location_on',
      'lock',
      'motorcycle',
      'notifications',
      'outdoor_grill',
      'pan_tool',
      'people',
      'pets',
      'phone_iphone',
      'pool',
      'print',
      'public',
      'school',
      'security',
      'settings',
      'shopping_cart',
      'sports_esports',
      'stars',
      'style',
      'supervised_user_circle',
      'toys',
      'traffic',
      'vpn_key',
    ];
  },

  getEmojis(type) {
    switch (type) {
      case 'on':
        return {
          1: require('../assets/img/emojis/newest/on1.png'),
          2: require('../assets/img/emojis/newest/on2.png'),
          3: require('../assets/img/emojis/newest/on3.png'),
          4: require('../assets/img/emojis/newest/on4.png'),
          5: require('../assets/img/emojis/newest/on5.png'),
          6: require('../assets/img/emojis/newest/on6.png'),
          7: require('../assets/img/emojis/newest/on7.png'),
          8: require('../assets/img/emojis/newest/on8.png'),
        };

      case null:
      case undefined:
      case 'off':
        return {
          1: require('../assets/img/emojis/newest/1.png'),
          2: require('../assets/img/emojis/newest/2.png'),
          3: require('../assets/img/emojis/newest/3.png'),
          4: require('../assets/img/emojis/newest/4.png'),
          5: require('../assets/img/emojis/newest/5.png'),
          6: require('../assets/img/emojis/newest/6.png'),
          7: require('../assets/img/emojis/newest/7.png'),
          8: require('../assets/img/emojis/newest/8.png'),
        };

      default:
        return null;
    }
  },

  isValidString(string) {
    if (
      string === '' ||
      string === null ||
      string === undefined ||
      string === ' ' ||
      string === '@NULL'
    ) {
      return false;
    }
    return true;
  },

  inArray(needle, haystack) {
    if (haystack) {
      const lengthArray = haystack.length;
      for (let i = 0; i < lengthArray; i += 1) {
        if (haystack[i] === needle) return true;
      }
    }
    return false;
  },
};
