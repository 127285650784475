import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

import ItemMenu from '../ItemMenu';
import Factories from '../../factories';
import LogomarcaPsico from '../../assets/img/logo_psicomanager_white.png';
import LogomarcaTerapia from '../../assets/img/icon_terapiainterativa.png';

import './styles.css';

const Header = ({ logout, diary, rota, whiteLabel }) => (
  <header>
    <div className="navbar-fixed hide-on-large-only">
      <nav className="purple">
        <div className="nav-wrapper">
          {rota === 'Download do Aplicativo' ? (
            <a href="#!" className="sidenav-trigger">
              <i className="material-icons">info_outline</i>
            </a>
          ) : (
            <a href="#!" data-target="slide-out" className="sidenav-trigger">
              <i className="material-icons">menu</i>
            </a>
          )}
          <span className="title-route">{rota || ''}</span>
          <ul className="right">
            <li>
              {rota === 'Download do Aplicativo' ? (
                <a href="#!" onClick={logout}>
                  <i className="material-icons">exit_to_app</i>
                </a>
              ) : (
                <a href="/notifications">
                  <i className="material-icons">notifications</i>
                </a>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <ul id="slide-out" className="sidenav sidenav-fixed">
      <li className="psicoLogo">
        {whiteLabel === '_psicomanager' ? (
          <div id="boxLogo" className="purple">
            <img
              id="logo"
              className="logo-psico"
              src={LogomarcaPsico}
              alt="PsicoManager"
            />
          </div>
        ) : (
          <div id="boxLogo" className="white">
            <img
              id="logo"
              className="logo-terapia"
              src={LogomarcaTerapia}
              alt="Terapia Interativa"
            />
          </div>
        )}
      </li>

      {!diary ? (
        <ItemMenu
          redirect="/diary"
          icon="mood"
          alias={intl.get('DIARIO')}
          divider
        />
      ) : null}

      <ItemMenu
        redirect="/consultation"
        icon="event"
        alias={intl.get('SESSOES')}
        permission={2}
        divider
      />

      {Factories.getVerificaPermissao(10) ? (
        <ItemMenu
          redirect="/anamnesis"
          icon="chrome_reader_mode"
          alias={intl.get('ANAMNESE')}
          divider
        />
      ) : null}

      <ItemMenu
        redirect="/payment"
        icon="credit_card"
        alias={intl.get('PAGAMENTOS')}
        permission={7}
        divider
      />

      <ItemMenu
        redirect="/config"
        icon="settings"
        alias={intl.get('OPCOES')}
        permission={2}
        divider
      />

      {/*
        {storage.cod_empresa === '232' && (
          <ItemMenu
            redirect="/diary"
            icon="mood"
            alias={intl.get('DIARIO_EMOCOES')}
            permission={3}
            divider
          />
        )}
      */}

      <ItemMenu
        redirect="/"
        icon="exit_to_app"
        alias={intl.get('SAIR')}
        permission={2}
        divider
        onclick={logout}
      />
    </ul>
  </header>
);

Header.propTypes = {
  logout: PropTypes.func,
  diary: PropTypes.bool,
  rota: PropTypes.string,
  whiteLabel: PropTypes.string,
  storage: PropTypes.shape({
    cod_clinica: PropTypes.string,
  }).isRequired,
};

Header.defaultProps = {
  logout: () => {},
  diary: true,
  rota: '',
  whiteLabel: '_psicomanager',
};

export default Header;
