import { combineReducers } from 'redux';

import moods from './moods';
import daily from './daily';
import users from './users';
import utils from './utils';
import payments from './payments';
import schedules from './schedules';
import professional from './professional';
import consultations from './consultations';
import register from './register';

export default combineReducers({
  moods,
  daily,
  users,
  utils,
  payments,
  schedules,
  professional,
  consultations,
  register,
});
