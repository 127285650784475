/**
 * Types
 */
export const Types = {
  LIST_ONDE_NOS_CONHECEU_REQUEST: '@utils/LIST_ONDE_NOS_CONHECEU_REQUEST',
  LIST_ONDE_NOS_CONHECEU_SUCCESS: '@utils/LIST_ONDE_NOS_CONHECEU_SUCCESS',
  LIST_ONDE_NOS_CONHECEU_FAILURE: '@utils/LIST_ONDE_NOS_CONHECEU_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  error: null,
  dataOndeNosConheceu: [],
};

export default function utils(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_ONDE_NOS_CONHECEU_REQUEST:
      return { ...state };

    case Types.LIST_ONDE_NOS_CONHECEU_SUCCESS:
      return { ...state, dataOndeNosConheceu: action.payload.data };

    case Types.LIST_ONDE_NOS_CONHECEU_FAILURE:
      return { ...state, error: true };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listOndeNosConheceuRequest: () => ({
    type: Types.LIST_ONDE_NOS_CONHECEU_REQUEST,
  }),

  listOndeNosConheceuSuccess: data => ({
    type: Types.LIST_ONDE_NOS_CONHECEU_SUCCESS,
    payload: { data },
  }),

  listOndeNosConheceuFailure: error => ({
    type: Types.LIST_ONDE_NOS_CONHECEU_FAILURE,
    payload: { error },
  }),
};
