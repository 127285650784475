/**
 * Types
 */
export const Types = {
  GET_REQUEST: '@professional/GET_REQUEST',
  GET_SUCCESS: '@professional/GET_SUCCESS',
  GET_FAILURE: '@professional/GET_FAILURE',
  GET_REQUEST_SCHEDULE: '@professional/GET_REQUEST_SCHEDULE',
  GET_SUCCESS_SCHEDULE: '@professional/GET_SUCCESS_SCHEDULE',
  GET_FAILURE_SCHEDULE: '@professional/GET_FAILURE_SCHEDULE',
  SAVE_REQUEST_SCHEDULE: '@professional/SAVE_REQUEST_SCHEDULE',
  SAVE_SUCCESS_SCHEDULE: '@professional/SAVE_SUCCESS_SCHEDULE',
  SAVE_FAILURE_SCHEDULE: '@professional/SAVE_FAILURE_SCHEDULE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  id: 0,
  url: '',
  data: [],
  schedules: [],
  defaultDate: null,
  professional: null,
  error: null,
  object: {},
  success: null,
};

export default function professionalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, url: action.payload.url };

    case Types.SAVE_REQUEST_SCHEDULE:
      return { ...state, object: action.payload.object };

    case Types.GET_REQUEST_SCHEDULE:
      return { ...state, id: action.payload.id };

    case Types.GET_SUCCESS:
      return {
        ...state,
        error: null,
        professional: action.payload.professional,
      };

    case Types.GET_SUCCESS_SCHEDULE:
      return {
        ...state,
        error: null,
        schedules: action.payload.schedules,
      };

    case Types.GET_FAILURE:
    case Types.GET_FAILURE_SCHEDULE:
    case Types.SAVE_FAILURE_SCHEDULE:
      return { ...state, error: action.payload.error, success: null };

    case Types.SAVE_SUCCESS_SCHEDULE:
      return { ...state, error: null, success: action.payload.success };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getProfessionalRequest: url => ({
    type: Types.GET_REQUEST,
    payload: { url },
  }),

  getProfessionalSuccess: professional => ({
    type: Types.GET_SUCCESS,
    payload: { professional },
  }),

  getProfessionalFailure: error => ({
    type: Types.GET_FAILURE,
    payload: { error },
  }),

  getSchedulesRequest: id => ({
    type: Types.GET_REQUEST_SCHEDULE,
    payload: { id },
  }),

  getSchedulesSuccess: schedules => ({
    type: Types.GET_SUCCESS_SCHEDULE,
    payload: { schedules },
  }),

  getSchedulesFailure: error => ({
    type: Types.GET_FAILURE_SCHEDULE,
    payload: { error },
  }),

  saveScheduleRequest: object => ({
    type: Types.SAVE_REQUEST_SCHEDULE,
    payload: { object },
  }),

  saveScheduleFailure: error => ({
    type: Types.SAVE_FAILURE_SCHEDULE,
    payload: { error },
  }),

  saveScheduleSucess: success => ({
    type: Types.SAVE_SUCCESS_SCHEDULE,
    payload: { success },
  }),
};
