import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import Sagas from './sagas';
import Reducers from './ducks';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const composer =
  process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares);

const Store = createStore(Reducers, composer);

sagaMiddleware.run(Sagas);

export default Store;
