import M from 'materialize-css';

import React, { Component } from 'react';

import API from '../../services/api';
import Loading from '../../components/Loading';

import { TOKEN_KEY } from '../../services/storage';

import './styles.css';

class logar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fUsuario: '',
      fSenha: '',
      pac: true,
      loading: true,
      whiteLabel: '_psicomanager',
    };
  }

  componentDidMount() {
    const whiteLabel = localStorage.getItem('@white_label');
    this.setState({
      loading: false,
      whiteLabel,
    });
  }

  handleSubmit = async e => {
    e.preventDefault();

    const { fUsuario, fSenha, pac } = this.state;
    if (!fUsuario || !fSenha) {
      M.toast({
        html: 'Preencha e-mail e senha para continuar!',
        displayLength: 5000,
      });
      this.setState({ loading: false });
    } else {
      const response = await API.post('/psico-auth', {
        fUsuario,
        fSenha,
        pac,
      });

      if (!response.data.records.error) {
        this.setState({ loading: true });
        localStorage.setItem(TOKEN_KEY, JSON.stringify(response.data.records));
        M.toast({
          html: 'É bom te ver novamente :)',
          displayLength: 3000,
        });
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      } else {
        M.toast({
          html: response.data.records.msg,
          displayLength: 3000,
        });
        this.setState({ fUsuario, fSenha });
      }
    }
  };

  username = async e => {
    this.setState({ fUsuario: e.target.value });
  };

  password = async e => {
    this.setState({ fSenha: e.target.value });
  };

  render() {
    const { loading, whiteLabel } = this.state;
    return loading ? (
      <Loading />
    ) : (
      <div id="login">
        <form onSubmit={this.handleSubmit}>
          {whiteLabel === '_psicomanager' ? (
            <>
              <img
                alt="PsicoManager Paciente"
                src={require('../../assets/img/icon.png')}
              />
              <h5>PsicoManager Paciente</h5>
              <span>Aproximando o profissional do paciente</span>
            </>
          ) : (
            <>
              <img
                alt="Terapia Interativa"
                src={require('../../assets/img/icon_terapiainterativa.png')}
                className="terapia-play"
              />
              <h5>Terapia Interativa</h5>
              <span>
                Plataforma que possibilita o uso de recursos terapêuticos em
                sessões de terapia online
              </span>
            </>
          )}
          <div className="center-align login">
            <div className="input-field col s6">
              <label htmlFor="email">E-mail</label>
              <input
                id="email"
                aria-label="E-mail"
                type="email"
                className="validate"
                onChange={this.username}
                value={this.fUsuario}
              />
            </div>
            <div className="input-field col s6">
              <label htmlFor="password">Senha</label>
              <input
                id="password"
                aria-label="Senha"
                type="password"
                className="validate"
                onChange={this.password}
                value={this.fSenha}
              />
            </div>
            <button
              className="btn waves-light btn-large light-blue darken-3"
              type="submit"
            >
              <span>ENTRAR</span>
            </button>
            <p>
              <a
                href="/esqueceu"
                alt=""
                className="waves-effect waves-teal btn-flat"
              >
                <span>Esqueceu a senha?</span>
              </a>
            </p>
          </div>
        </form>
      </div>
    );
  }
}

logar.propTypes = {};

logar.defaultProps = {};

export default logar;
