import { call, put } from 'redux-saga/effects';

import { Creators as UtilActions } from '../ducks/utils';

import API from '../../services/api';

export function* listOndeNosConheceu() {
  try {
    const { data } = yield call(API.get, '/empresa/get-onde-conheceu');
    if (data.records.error) {
      yield put(
        UtilActions.listOndeNosConheceuFailure('Nenhum dado encontrado!')
      );
    } else {
      const { records } = data;
      yield put(UtilActions.listOndeNosConheceuSuccess(records.data));
    }
  } catch (err) {
    yield put(
      UtilActions.listOndeNosConheceuFailure('Erro interno ao listar os dados!')
    );
  }
}
