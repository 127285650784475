/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import '@material-ui/icons';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { BsSearch } from 'react-icons/bs';

import './DropDown.css';

function DropDown({
  children,
  label,
  small,
  middle,
  subTitle,
  isOpend,
  setIsOpend,
  shouldShowSearch,
  onKeyDown,
}) {
  return (
    <div
      className={`containerDropDown ${small && 'small'} ${middle && 'middle'}`}
    >
      <div
        id="DropDownContainer"
        className={` ${small && 'small'} ${middle && 'middle'}`}
      >
        <div className="titleContainer">
          <p className="label">{label}</p>
        </div>
        <div
          className={`selectBox ${isOpend ? 'opend' : 'closed'}`}
          onClick={setIsOpend}
        >
          <div id="titleBox">
            <p className={`subTitle ${(middle || small) && 'smallText'}`}>
              {subTitle}
            </p>
            {isOpend ? (
              <KeyboardArrowUpIcon style={{ fontSize: 20, color: '#7D8C94' }} />
            ) : (
              <KeyboardArrowDownIcon
                style={{ fontSize: 20, color: '#7D8C94' }}
              />
            )}
          </div>
        </div>
        {isOpend && (
          <div className="OpendBox">
            {shouldShowSearch && (
              <div className="searchInputContainer" onKeyUp={onKeyDown}>
                <BsSearch />
                <input type="text" className="Input" />
              </div>
            )}
            {children}
          </div>
        )}
      </div>
    </div>
  );
}

export default DropDown;
