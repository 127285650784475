import { all, takeLatest } from 'redux-saga/effects';

import { listMoods, getMood, saveMood, deleteMood } from './moods';
import { Types as MoodTypes } from '../ducks/moods';

import { listSchedules, reschedulePatient } from './schedules';
import { Types as SheduleTypes } from '../ducks/schedules';

import { listConsultations, getConsultation } from './consultations';
import { Types as ConsultationTypes } from '../ducks/consultations';

import { listPayments } from './payments';
import { Types as PaymentTypes } from '../ducks/payments';

import { getProfessional, getSchedules, saveSchedule } from './professional';
import { Types as ProfessionalTypes } from '../ducks/professional';

import { listGetClinic, saveRegister } from './register';
import { Types as RegisterTypes } from '../ducks/register';

import { listOndeNosConheceu } from './utils';
import { Types as UtilTypes } from '../ducks/utils';

import {
  getUser,
  saveUser,
  getAnamnese,
  saveAnamnese,
  getPermissions,
} from './users';
import { Types as UserTypes } from '../ducks/users';

import { Types as DailyTypes } from '../ducks/daily';
import {
  listDaily,
  saveDaily,
  saveAnswer,
  saveActivity,
  listHistory,
  listActivities,
  getDailyAnswer,
} from './daily';

export default function* rootSaga() {
  yield all([
    takeLatest(UserTypes.GET_REQUEST, getUser),
    takeLatest(MoodTypes.GET_REQUEST, getMood),
    takeLatest(UserTypes.SAVE_REQUEST, saveUser),
    takeLatest(MoodTypes.SAVE_REQUEST, saveMood),
    takeLatest(MoodTypes.LIST_REQUEST, listMoods),
    takeLatest(DailyTypes.LIST_REQUEST, listDaily),
    takeLatest(DailyTypes.SAVE_REQUEST, saveDaily),
    takeLatest(MoodTypes.DELETE_REQUEST, deleteMood),
    takeLatest(PaymentTypes.LIST_REQUEST, listPayments),
    takeLatest(SheduleTypes.LIST_REQUEST, listSchedules),
    takeLatest(DailyTypes.SAVE_ANSWER_REQUEST, saveAnswer),
    takeLatest(UserTypes.GET_ANAMNESE_REQUEST, getAnamnese),
    takeLatest(DailyTypes.LIST_HISTORY_REQUEST, listHistory),
    takeLatest(DailyTypes.SAVE_ACTIVITY_REQUEST, saveActivity),
    takeLatest(ConsultationTypes.GET_REQUEST, getConsultation),
    takeLatest(ProfessionalTypes.GET_REQUEST, getProfessional),
    takeLatest(UserTypes.SAVE_ANAMNESIS_REQUEST, saveAnamnese),
    takeLatest(ProfessionalTypes.GET_REQUEST_SCHEDULE, getSchedules),
    takeLatest(ProfessionalTypes.SAVE_REQUEST_SCHEDULE, saveSchedule),
    takeLatest(ConsultationTypes.LIST_REQUEST, listConsultations),
    takeLatest(DailyTypes.LIST_ACTIVITIES_REQUEST, listActivities),
    takeLatest(DailyTypes.GET_DAILY_ANSWER_REQUEST, getDailyAnswer),
    takeLatest(SheduleTypes.RESCHEDULE_PATIENT_REQUEST, reschedulePatient),
    takeLatest(UtilTypes.LIST_ONDE_NOS_CONHECEU_REQUEST, listOndeNosConheceu),
    takeLatest(RegisterTypes.GET_CLINIC_REQUEST, listGetClinic),
    takeLatest(UserTypes.GET_PERMISSIONS_REQUEST, getPermissions),
    takeLatest(RegisterTypes.POST_INSERT_USER_REQUEST, saveRegister),
  ]);
}
