import { call, put } from 'redux-saga/effects';

import { Creators as ProfessionalActions } from '../ducks/professional';

import API from '../../services/api';

export function* getProfessional(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/site-busca/get-profissional-site/?q=(emp:${payload.url})`
    );

    if (data.records) {
      const { records } = data;
      yield put(ProfessionalActions.getProfessionalSuccess(records));
    } else {
      yield put(
        ProfessionalActions.getProfessionalFailure('Profissional inexistente!')
      );
    }
  } catch (err) {
    yield put(
      ProfessionalActions.getProfessionalFailure(
        'Erro interno no módulo de recolher os dados do profissional!'
      )
    );
  }
}

export function* getSchedules(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/site-busca/listar-horarios-disp/${payload.id}`
    );
    if (data.records) {
      const { records } = data;
      yield put(ProfessionalActions.getSchedulesSuccess(records));
    } else {
      yield put(ProfessionalActions.getSchedulesFailure('Nenhum horário!'));
    }
  } catch (err) {
    yield put(
      ProfessionalActions.getSchedulesFailure(
        'Erro interno no módulo de recolher os horários!'
      )
    );
  }
}

export function* saveSchedule(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.post,
      '/site-busca/agendar-paciente',
      payload.object
    );
    const { records } = data;
    if (records.error) {
      yield put(ProfessionalActions.saveScheduleSucess(records.msg));
    } else {
      yield put(ProfessionalActions.saveScheduleFailure(records.msg));
    }
  } catch (err) {
    yield put(
      ProfessionalActions.saveScheduleFailure(
        'Erro interno no módulo de salvar os dados do agendamento!'
      )
    );
  }
}
