/* eslint-disable react/button-has-type */
import React from 'react';

import './Button.css';

// eslint-disable-next-line react/prop-types
function Button({ onClick, label, disabled }) {
  return (
    <button className={`Button ${label}`} onClick={onClick} disabled={disabled}>
      {label}
    </button>
  );
}

export default Button;
