/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import './ProgressBarRegister.css';

import { FaCircle, FaCheck } from 'react-icons/fa';

const ProgressBarRegister = ({ totalChecks, lastCheckedId }) => {
  const total = new Array(totalChecks);
  total.fill(1);

  return (
    <div className="Container">
      {total.map((_check, index) => (
        <div className="CheckContainer" key={index}>
          <div
            className="CircleBackground"
            style={{
              backgroundColor:
                index < lastCheckedId - 1 ? '#E7DBF3' : '#E8E9E9',
              border: index < lastCheckedId - 1 && '2px solid #8664A7',
            }}
          >
            {index < lastCheckedId - 1 ? (
              <FaCheck style={{ fontSize: '1.1rem', color: '#8664A7' }} />
            ) : (
              <FaCircle
                style={{
                  fontSize: '0.7rem',
                  color: '#AEB9BF',
                }}
              />
            )}
          </div>
          {index == 0 && (
            <div
              className="RightBar"
              style={{
                backgroundColor:
                  index < lastCheckedId - 1 ? '#E7DBF3' : '#E8E9E9',
              }}
            />
          )}
          {index > 0 && index < totalChecks - 1 && (
            <div
              className="FullBar"
              style={{
                backgroundColor:
                  index < lastCheckedId - 1 ? '#E7DBF3' : '#E8E9E9',
              }}
            />
          )}
          {index == totalChecks - 1 && (
            <div
              className="LeftBar"
              style={{
                backgroundColor:
                  index < lastCheckedId - 1 ? '#E7DBF3' : '#E8E9E9',
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressBarRegister;
