import React from 'react';

const Consultation = React.lazy(() => import('./pages/consultation'));
const Editconsult = React.lazy(() => import('./pages/consultation/edit'));
const Mood = React.lazy(() => import('./pages/mood'));
const Editmood = React.lazy(() => import('./pages/mood/edit'));
const New = React.lazy(() => import('./pages/mood/new'));
const Payment = React.lazy(() => import('./pages/payment'));
const Scheduling = React.lazy(() => import('./pages/scheduling'));
const Help = React.lazy(() => import('./pages/help'));
const Found = React.lazy(() => import('./pages/notfound'));
const Config = React.lazy(() => import('./pages/configs'));
const Diary = React.lazy(() => import('./pages/diary'));
const EditDiary = React.lazy(() => import('./pages/diary/edit'));
const ChangePassword = React.lazy(() =>
  import('./pages/profile/change-password')
);
const Profile = React.lazy(() => import('./pages/profile'));
const Tasks = React.lazy(() => import('./pages/tasks'));
const Notifications = React.lazy(() => import('./pages/notifications'));
const Anamnesis = React.lazy(() => import('./pages/anamnesis'));
const DownloadApp = React.lazy(() => import('./pages/download-app'));

const routes = [
  {
    path: '/consultation',
    exact: true,
    name: 'Sessões',
    component: Consultation,
  },
  {
    path: '/scheduling',
    name: 'Agendamentos',
    component: Scheduling,
  },
  {
    path: '/consultation/:id',
    name: 'Editar',
    component: Editconsult,
  },
  {
    path: '/mood',
    exact: true,
    name: 'Humor',
    component: Mood,
  },
  {
    path: '/mood/:id',
    name: 'Editar Humor',
    component: Editmood,
  },
  {
    path: '/new-mood',
    name: 'Novo Humor',
    component: New,
  },
  {
    path: '/payment',
    name: 'Pagamentos',
    component: Payment,
  },
  {
    path: '/help',
    name: 'Ajuda',
    component: Help,
  },
  {
    path: '/config',
    name: 'Configurações',
    component: Config,
  },
  {
    path: '/change-password',
    name: 'Alterar Senha',
    component: ChangePassword,
  },
  {
    path: '/profile',
    name: 'Perfil',
    component: Profile,
  },
  {
    path: '/diary/:tab?',
    exact: true,
    name: 'Diário de Emoções',
    component: Diary,
  },
  {
    path: '/diary/edit/:id',
    name: 'Editar Diário',
    component: EditDiary,
  },
  {
    path: '/tasks',
    name: 'Tarefas',
    component: Tasks,
  },
  {
    path: '/notifications',
    name: 'Notificações',
    component: Notifications,
  },
  {
    path: '/anamnesis',
    name: 'Preencher Anamnese',
    component: Anamnesis,
  },
  {
    path: '/download-app',
    name: 'Download do Aplicativo',
    component: DownloadApp,
  },
  {
    path: '*',
    name: 'Not Found',
    component: Found,
  },
];

export default routes;
