/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
import React from 'react';

import './Input.css';

function Input({
  value,
  placeholder,
  onChange,
  Label,
  maxLength,
  required,
  type,
  name,
  small,
  extraSmall,
  middle,
  large,
}) {
  return (
    <div
      className={`containerInput ${small && 'small'} ${extraSmall &&
        'extraSmall'} ${middle && 'middle'} ${large && 'large'}`}
    >
      <div className="labelContainer">
        <p id="labelInput">{Label}</p>
        {required && <span className="required">*</span>}
      </div>
      <input
        maxLength={maxLength}
        type="text"
        onChange={onChange}
        value={value}
        id="input"
        required={required}
        type={type}
        name={name}
        placeholder={placeholder}
        disableUnderline
      />
    </div>
  );
}

export default Input;
