import React from 'react';

import './styles.css';

const Loading = () => (
  <div id="loading">
    <img
      id="imagemLoader"
      alt="Processando"
      src="https://wpamelia.com/wp-content/uploads/2018/11/ezgif-2-6d0b072c3d3f.gif"
    />
  </div>
);

export default Loading;
